<template>
  <div class="services" id="homeServices">
    <div class="texts">
      <h1>Нашите услуги,</h1>
      <h1>цени и резултати</h1>
      <p>Образът се получава върху носител по избор на пациента, или на лекуващият дентист - с възможност за оптичен диск, или разпечатан върху прозрачна плака чрез висококачествен медицински принтер.</p>
      <p>Също така резултатите са достъпни и в нашата онлайн система</p>
      <Button
        class="button--bigger round"
        :class="{ 'button--active' : isPricesPanelOpen }"
        @click="isPricesPanelOpen = !isPricesPanelOpen"
      >
        {{ isPricesPanelOpen ? 'ЗАТВОРИ' : 'НАШИЯТ'}} ЦЕНОРАЗПИС
        <span class="icon">d</span>
      </Button>
    </div>
    <div class="uls">
      <ul>
        <li class="icon">0</li>
        <li class="strong">Ортопантомографски образни изследвания</li>
        <li @click="selectedService = 1">
          <span>Дигитална ортопантомография</span>
        </li>
        <li @click="selectedService = 2">
          <span>Дигитална ортопантомография отворена/затворена уста (ТМС)</span>
        </li>
        <li @click="selectedService = 3">
          <span>Секторна ортопантомография</span>
        </li>
        <li class="icon">y</li>
        <li class="strong">Рентгенографии на череп</li>
        <li @click="selectedService = 4">
          <span>Дигитална телерентгенография в лицева проекция</span>
        </li>
        <li @click="selectedService = 5">
          <span>Дигитална телерентгенография в профилна проекция</span>
        </li>
        <li @click="selectedService = 6">
          <span>Околоносни кухини - дигитална</span>
        </li>
        <li @click="selectedService = 7">
          <span>Рентгенография по Хирц</span>
        </li>
        <li @click="selectedService = 8">
          <span>Рентгенография на череп - анфас и профил</span>
        </li>
        <li class="icon">z</li>
        <li class="strong">3D - Компютърна томография (CBCT)</li>
        <li @click="selectedService = 9">
          <span>Две челюсти - долна и горна</span>
        </li>
        <li @click="selectedService = 10">
          <span>Една челюст</span>
        </li>
        <li @click="selectedService = 11">
          <span>Малък обем 4 х 4</span>
        </li>
        <li @click="selectedService = 111">
          <span>Компютърна томография TMJ</span>
        </li>
      </ul>
      <ul>
        <li class="icon">x</li>
        <li class="strong">Интраорални рентгенографии, зъбни снимки</li>
        <li @click="selectedService = 12">
          <span>Бисектрисна техника по Dieck</span>
        </li>
        <li @click="selectedService = 13">
          <span>По паралелна техника</span>
        </li>
        <li @click="selectedService = 14">
          <span>По Bite-Wing техника</span>
        </li>
        <li @click="selectedService = 15">
          <span>Парадонтален статус</span>
        </li>
        <li class="icon">1</li>
        <li class="strong">Други центражи - рентгенографии</li>
        <li @click="selectedService = 16">
          <span>Аксиална по Simpson (Долна челюст)</span>
        </li>
        <li @click="selectedService = 17">
          <span>Коса по Belot (Горна челюст)</span>
        </li>
        <li @click="selectedService = 18">
          <span>На шийни прешлени - дигитална</span>
        </li>
        <li @click="selectedService = 19">
          <span>На китка (лицева и профилна проекция) - дигитална</span>
        </li>
        <li @click="selectedService = 20">
          <span>Носни кости</span>
        </li>
      </ul>
    </div>
    <PricesPanel v-if="isPricesPanelOpen" @close-panel="isPricesPanelOpen = false" />
    <transition name="fade">
      <ServicesModal
        v-if="selectedService !== 0"
        :service="selectedService"
        @close="selectedService = 0"
      />
    </transition>
  </div>
</template>

<script>
import Button from "@/components/Button";
import PricesPanel from "./PricesPanel";
import ServicesModal from "./ServicesModal";
export default {
  components: {
    Button,
    PricesPanel,
    ServicesModal
  },
  data() {
    return {
      isPricesPanelOpen: false,
      selectedService: 0
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
.services {
  align-items: center;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#eef2f3+0,eef2f3+85,dbdfe5+85,dbdfe5+100 */
  background: rgb(238, 242, 243); /* Old browsers */
  background: -moz-linear-gradient(
    75deg,
    rgba(238, 242, 243, 1) 0%,
    rgba(238, 242, 243, 1) 85%,
    rgba(219, 223, 229, 1) 85%,
    rgba(219, 223, 229, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    75deg,
    rgba(238, 242, 243, 1) 0%,
    rgba(238, 242, 243, 1) 85%,
    rgba(219, 223, 229, 1) 85%,
    rgba(219, 223, 229, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    75deg,
    rgba(238, 242, 243, 1) 0%,
    rgba(238, 242, 243, 1) 85%,
    rgba(219, 223, 229, 1) 85%,
    rgba(219, 223, 229, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eef2f3', endColorstr='#dbdfe5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  display: flex;
  font-family: "Roboto", sans-serif;
  min-height: 100vh;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 150px;
  position: relative;
  &::before {
    background-image: url("../../assets/img/home/bg-services.jpg");
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.05;
  }
}

.texts,
ul {
  position: relative;
  z-index: 1;
}

.texts {
  width: 30%;
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 300;
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
  p {
    color: #0077c7;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .button {
    background-color: #0077c7;
    color: #fff;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 4rem;
    .icon {
      font-weight: 700;
      margin: 0 0 0 3rem;
      @include transition(all $transition $cubic);
    }
    &.button--active {
      background-color: $accent;
      .icon {
        @include transform(scale(-1));
      }
    }
  }
}

.uls {
  display: flex;
  width: 60%;
}
ul {
  padding: 20px 0;
  width: 50%;
  li {
    &.icon {
      color: #0077c7;
      font-size: 3rem;
      margin: 1.5rem 0;
    }
    &.strong {
      color: #183042;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    &:not(.strong):not(.icon) {
      cursor: pointer;
      font-size: 1.2rem;
      margin: 15px 0;
      @include transition(all $transition $cubic);

      span {
        text-decoration: underline;
      }

      &:hover {
        color: #0077c7;
        text-decoration: none;
        font-weight: 700;
      }

      &::before {
        content: "d";
        color: #00c2a9;
        font-family: "icons";
        font-weight: 700;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
  }
}

@media screen and (max-width: $xl) {
  .texts {
    h1 {
      font-size: 3rem;
      line-height: 3rem;
    }
    p {
      font-size: 1.1rem;
    }
    .button {
      margin-top: 3rem;
    }
  }

  ul {
    li {
      &.icon {
        font-size: 2.5rem;
        margin: 1.2rem 0;
      }
      &.strong {
        color: #183042;
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
      }
      &:not(.strong):not(.icon) {
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .services {
    padding-left: 50px;
  }
  .texts {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
  ul {
    padding-right: 1rem;
  }
}

@media screen and (max-width: $s) {
  .services {
    flex-direction: column;
    padding: 20px;
  }
  .texts {
    text-align: center;
    width: 100%;
  }
  .uls {
    flex-direction: column;
    width: 100%;
  }
  ul {
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: $xs) {
  .texts {
    h1 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
</style>