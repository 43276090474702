<template>
  <div class="home__showcase">
    <div class="background"></div>
    <div class="left">
      <div class="logo--mobile">
        <img src="@/assets/img/logo.svg" alt="3V-Rentgen" />
      </div>
      <div class="texts">
        <h1>Рентгенографии на зъби, ортопантомографии (панорамни), телерентгенографии, компютърна томография (CBCT 3D)</h1>
        <p
          class="accent"
        >В лабораторията на ул. “Люлин планина” № 40 е инсталиран нов модерен щадящ пациентите немски томограф за панорамни зъбни снимки - VistaPano S Ceph на Dürr Dental</p>
        <br>
        <p
          class="accent"
        >На Ваше разположение са специалистите в Денталната образна диагностика -  доц. д-р Владимир Николов д.м., доайен в денталната рентгенология и д-р Мариан Димитров</p>
      </div>
      <ul class="steps">
        <li>
          <p class="accent">
            1
            <span class="icon">w</span>
          </p>
          <div class="texts">
            <h1>Снимка</h1>
            <p class="accent">от 4.1 до 7.0 секунди</p>
          </div>
        </li>
        <li>
          <p class="accent">
            2
            <span class="icon">w</span>
          </p>
          <div class="texts">
            <h1>Резултат</h1>
            <p class="accent">от 3 до 5 минути</p>
          </div>
        </li>
        <li>
          <p class="accent">
            3
            <span class="icon">w</span>
          </p>
          <div class="texts">
            <h1>е-Картон</h1>
            <p class="accent">с история на пациента</p>
          </div>
        </li>
        <li class="li--button">
          <Button @click="$emit('open-app')" class="button--bigger round button--width">
            <span>
              <span>ВХОД/РЕГИСТРАЦИЯ</span>
              <span>ОНЛАЙН РЕЗУЛТАТИ</span>
            </span>
          </Button>
        </li>
      </ul>
    </div>
    <div class="right">
      <img src="@/assets/img/home/hero.png" alt />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
export default {
  components: {
    Button
  }
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.home__showcase {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c2a9+0,2a5584+85,333c6a+85,333c6a+100 */
  background: rgb(0, 194, 169); /* Old browsers */
  background: -moz-linear-gradient(
    -15deg,
    rgba(0, 194, 169, 1) 0%,
    rgba(42, 85, 132, 1) 85%,
    rgba(51, 60, 106, 1) 85%,
    rgba(51, 60, 106, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    -15deg,
    rgba(0, 194, 169, 1) 0%,
    rgba(42, 85, 132, 1) 85%,
    rgba(51, 60, 106, 1) 85%,
    rgba(51, 60, 106, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    105deg,
    rgba(0, 194, 169, 1) 0%,
    rgba(42, 85, 132, 1) 85%,
    rgba(51, 60, 106, 1) 85%,
    rgba(51, 60, 106, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c2a9', endColorstr='#333c6a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  display: flex;
  color: #fff;
  font-family: 'Roboto';
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  padding-top: 200px;
  position: relative;
  // z-index: -1;
}
.background {
  background-size: cover;
  background-image: url('../../assets/img/home/bg-showcase.jpg');
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  opacity: 0.1;
  mix-blend-mode: luminosity;
}
.accent {
  color: #00ffcb;
}
.left,
.right {
  position: relative;
  z-index: 1;
  width: 50%;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 65px;
  padding-right: 0;

  > .texts {
    h1 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 4rem;
      margin-bottom: 1rem;
    }
    p {
      font-family: 'Roboto Condensed', sans-serif;
      font-size: 1.5rem;
    }
  }
}
.steps {
  display: flex;
  li {
    align-items: center;
    display: inline-flex;
    &:nth-child(1),
    &:nth-child(2) {
      margin-right: 10px;
    }
    > p {
      align-items: center;
      display: inline-flex;
      font-size: 4rem;
      font-weight: 100;
      .icon {
        margin: 0 -0.5rem 0 -1rem;
        font-size: 3.5rem;
      }
    }
    .texts {
      h1 {
        font-size: 3rem;
        font-weight: 300;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: 1.4rem;
        font-family: 'Roboto Condensed', sans-serif;
      }
    }
  }
}
.right {
  align-items: flex-end;
  display: flex;
  mix-blend-mode: lighten;
  padding-bottom: 20px;
  img {
    height: auto;
    width: 100%;
  }
}

.logo--mobile,
.steps .li--button {
  display: none;
}

@media screen and (max-width: $xl) {
  .left {
    width: 55%;
    > .texts {
      h1 {
        font-size: 3rem;
        line-height: 3rem;
      }
      p {
        font-size: 1.3rem;
      }
    }
  }
  .steps {
    display: flex;
    li {
      > p {
        align-items: center;
        display: inline-flex;
        font-size: 3rem;
        font-weight: 100;
        .icon {
          margin: 0 -0.5rem 0 -1rem;
          font-size: 3rem;
        }
      }
      .texts {
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 1.2rem;
        }
      }
    }
  }
  .right {
    width: 45%;
  }
}
@media screen and (max-width: $l) {
  .left {
    width: 60%;
  }
  .right {
    width: 40%;
  }
}

@media screen and (max-width: $sm) {
  .home__showcase {
    padding: 0;
  }
  .logo--mobile {
    align-items: center;
    display: flex;
    height: 100px;
    justify-content: center;
    width: 100%;
    img {
      height: 100%;
    }
  }
  .left {
    align-items: center;
    padding: 50px 65px;
    text-align: center;
    width: 100%;
    > .texts {
      h1 {
        font-size: 2rem;
        line-height: 2rem;
      }
      p {
        font-size: 1.2rem;
      }
    }
  }
  .steps {
    flex-wrap: wrap;
    justify-content: center;
    li {
      > p {
        font-size: 2rem;
        .icon {
          margin: 0 0 0;
          font-size: 2rem;
        }
      }
      .texts {
        h1 {
          font-size: 1.8rem;
          margin-bottom: 0;
        }
        p {
          font-size: 1.1rem;
        }
      }
    }
    .li--button {
      display: flex;
      padding-top: 20px;
      justify-content: center;
      width: 100%;
      .button {
        span:not(.icon) {
          display: flex;
          flex-direction: column;
          white-space: nowrap;
          &:first-child {
            color: #000;
            font-size: 1.1rem;
          }
          &:last-child {
            color: #7a7a7a;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .right {
    bottom: 0;
    position: absolute;
    right: 0;
  }
}
@media screen and (max-width: $s) {
  .logo--mobile {
    top: 0;
    left: 0;
    height: 100px;
    position: absolute;
    img {
      height: 80px;
    }
  }
  .left {
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding: 100px 20px $sm-header-height;
    width: 100%;
    > .texts {
      text-align: center;
    }

    .steps {
      li:not(.li--button) {
        width: calc(33.33% - 6.66px);
      }
    }
  }

  .right {
    position: absolute;
    top: 65%;
    @include transform(translateY(-50%));
    width: 100%;
  }
}
@media screen and (max-width: $xs) {
  .left {
    > .texts {
      h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }
  .steps {
    li {
      > p {
        font-size: 1.2rem;
        font-weight: 300;
        .icon {
          font-size: 1.2rem;
        }
      }
      .texts {
        h1 {
          font-size: 1.1rem;
          white-space: nowrap;
        }
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
}
</style>
