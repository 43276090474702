import { render, staticRenderFns } from "./InquiryPanel.vue?vue&type=template&id=14b0dd88&scoped=true"
import script from "./InquiryPanel.vue?vue&type=script&lang=js"
export * from "./InquiryPanel.vue?vue&type=script&lang=js"
import style0 from "./InquiryPanel.vue?vue&type=style&index=0&id=14b0dd88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b0dd88",
  null
  
)

export default component.exports