<template>
  <div class="service-modal-wrapper">
    <div class="modal">
      <header>
        <div class="texts">
          <template v-if="service === 1">
            <p>Ортопантомографски образни изследвания</p>
            <h1>Ортопантомография (панорамна) ОПГ, OPG</h1>
          </template>
          <template v-if="service === 2">
            <p>Ортопантомографски образни изследвания</p>
            <h1>Ортопантомография на Темпоро-мандибуларни стави (ТМС)</h1>
          </template>
          <template v-if="service === 3">
            <p>Ортопантомографски образни изследвания</p>
            <h1>Секторна ортопантомография</h1>
          </template>
          <template v-if="service === 4">
            <p>Рентгенографии на череп</p>
            <h1>Дигитална телерентгенография в лицева проекция</h1>
          </template>
          <template v-if="service === 5">
            <p>Рентгенографии на череп</p>
            <h1>Дигитална телерентгенография в профилна проекция</h1>
          </template>
          <template v-if="service === 6">
            <p>Рентгенографии на череп</p>
            <h1>Околоносни кухини - дигитална</h1>
          </template>
          <template v-if="service === 7">
            <p>Рентгенографии на череп</p>
            <h1>Рентгенография по Хирц</h1>
          </template>
          <template v-if="service === 8">
            <p>Рентгенографии на череп</p>
            <h1>Рентгенография на череп - анфас и профил</h1>
          </template>
          <template v-if="service === 9">
            <p>3D - Компютърна томография (CBCT)</p>
            <h1>Две челюсти - долна и горна</h1>
          </template>
          <template v-if="service === 10">
            <p>3D - Компютърна томография (CBCT)</p>
            <h1>Една челюст</h1>
          </template>
          <template v-if="service === 11">
            <p>3D - Компютърна томография (CBCT)</p>
            <h1>Малък обем 4 х 4</h1>
          </template>
          <template v-if="service === 111">
            <p>3D - Компютърна томография (CBCT)</p>
            <h1>Компютърна томография TMJ</h1>
          </template>
          <template v-if="service === 12">
            <p>Интраорални рентгенографии</p>
            <h1>Интраорална, сегментна рентгенография по Dieck, зъбна снимка</h1>
          </template>
          <template v-if="service === 13">
            <p>Интраорални рентгенографии</p>
            <h1>Паралелна интрорална рентгенография, зъбна снимка</h1>
          </template>
          <template v-if="service === 14">
            <p>Интраорални рентгенографии</p>
            <h1>По Bite-Wing техника (Кариес диагностика)</h1>
          </template>
          <template v-if="service === 15">
            <p>Интраорални рентгенографии</p>
            <h1>Парадонтален статус</h1>
          </template>
          <template v-if="service === 16">
            <p>Други центражи - рентгенографии</p>
            <h1>Аксиална по Simpson (Долна челюст)</h1>
          </template>
          <template v-if="service === 17">
            <p>Други центражи - рентгенографии</p>
            <h1>Коса по Belot (Горна челюст)</h1>
          </template>
          <template v-if="service === 18">
            <p>Други центражи - рентгенографии</p>
            <h1>На шийни прешлени - дигитална</h1>
          </template>
          <template v-if="service === 19">
            <p>Други центражи - рентгенографии</p>
            <h1>На китка (лицева и профилна проекция) - дигитална</h1>
          </template>
          <template v-if="service === 20">
            <p>Други центражи - рентгенографии</p>
            <h1>Носни кости</h1>
          </template>
        </div>
        <span class="icon" @click="$emit('close')">L</span>
      </header>
      <img
        class="image--mobile"
        src="@/assets/img/home/services/1m.jpg"
        alt="Image 1"
        v-if="service === 1"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/2m.jpg"
        alt="Image 2"
        v-if="service === 2"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/3m.jpg"
        alt="Image 3"
        v-if="service === 3"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/4m.jpg"
        alt="Image 4"
        v-if="service === 4"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/5m.jpg"
        alt="Image 5"
        v-if="service === 5"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/6m.jpg"
        alt="Image 6"
        v-if="service === 6"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/7m.jpg"
        alt="Image 7"
        v-if="service === 7"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/8m.gif"
        alt="Image 8"
        v-if="service === 8"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/9m.jpg"
        alt="Image 9"
        v-if="service === 9"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/10m.gif"
        alt="Image 10"
        v-if="service === 10"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/11m.jpg"
        alt="Image 11"
        v-if="service === 11"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/111m.gif"
        alt="Image 111"
        v-if="service === 111"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/12m.jpg"
        alt="Image 12"
        v-if="service === 12"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/13m.jpg"
        alt="Image 13"
        v-if="service === 13"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/14m.jpg"
        alt="Image 14"
        v-if="service === 14"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/15m.jpg"
        alt="Image 15"
        v-if="service === 15"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/16m.jpg"
        alt="Image 16"
        v-if="service === 16"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/17m.jpg"
        alt="Image 17"
        v-if="service === 17"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/18m.jpg"
        alt="Image 18"
        v-if="service === 18"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/19m.jpg"
        alt="Image 19"
        v-if="service === 19"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/services/20m.jpg"
        alt="Image 20"
        v-if="service === 20"
      />
      <main :class="`main-${service}`">
        <aside>
          <template v-if="service === 1">
            <p>Тази графия дава цялостния изглед на челюстите, темпоромандибуларните стави, назалната област, част от синусите</p>
            <p>Диагностицират се болестни състояния на зъбите, костни периодонтални изменения, ретинирани и допълнителни зъби, патологични процеси, фрактури, кисти и положението им спрямо костта, състоянието на алвеоларния гребен, съотношението между синусния контур и апексите на зъбите.</p>
          </template>
          <template v-if="service === 2">
            <p>Сравнително изследване на двете Темпоро-мандибуларни стави (ТМС) при положение на отворена и затворена уста, с цел диагностициране на патологични изменения, като например остеоартрит</p>
          </template>
          <template v-if="service === 3">
            <p>Тази графия дава частичен изглед на челюстите, в избраната от дентиста област</p>
            <p>Диагностицират се болестни състояния на зъбите, костни периодонтални изменения, ретинирани и допълнителни зъби, патологични процеси, фрактури, кисти и положението им спрямо костта, състоянието на алвеоларния гребен, съотношението между синусния контур и апексите на зъбите.</p>
          </template>
          <template v-if="service === 4">
            <p>С тези методики на изследване се диагностицират изменения на костните структури на черепа и лицевите кости, като фрактури, възпалителни и неопластични заболявания.</p>
          </template>
          <template v-if="service === 5">
            <p>Цефалометрична рентгенография, Телерентгенография</p>
            <p>Тази методика на изследване се използва най често от ортодонтите за прецизиране и изготвяне на план за лечение, като се съпоставят зъбите, челюстните и лицевите съотношения</p>
          </template>
          <template v-if="service === 6">
            <p>Тази рентгенография се използва за диагностициране на изменения свързани със заболявания на синусите, като синузит, полипи, кисти и др.</p>
          </template>
          <template v-if="service === 7">
            <p>Рентгенография на череп, (фасова графия, по метода на Хирц и центраж за носни кости)</p>
            <p>С тези методики на изследване се диагностицират изменения на костните структури на черепа и лицевите кости, като фрактури, възпалителни и неопластични заболявания.</p>
          </template>
          <template v-if="service === 8">
            <p>С тези методики на изследване се диагностицират изменения на костните структури на черепа и лицевите кости, като фрактури, възпалителни и неопластични заболявания.</p>
          </template>
          <template v-if="service === 9">
            <p>Изследването чрез конично лъчева компютърна томография (Cone Beam Computed Tomography, СВСТ) познато и като 3D изследване на челюстите и зъбите.</p>
            <p>Чрез СВСТ се получават образи на зъбите и. челюстите във вид на срезове във всички равнини. Върху образите могат да се правят точни измервания на разстояния и ъгли за целите на имплантологията, ортогнатната хирургия, ортодонтията, ендодонтията и навсякъде където те са необходими.</p>
            <p>Удостоения със златен медал на изложението в Kьолн Японски апарат MORITA VERAVIEW X800. 3d и 2d скенер за зъби притежава възможности включващи:</p>
            <ul>
              <li>Уникална рязкост при размер на вокселите до 80 μm;</li>
              <li>Разделителна способност 2.5 LP/mm – за визуализация и на най - фините</li>
              <li>анатомични структури на зъбите;</li>
              <li>Прецизен дигитален образ с минимализиране на металните артефакти;</li>
              <li>СBCT с голям избор на различни обеми на изследване на челюстите (FOV) – от 4х4 см до 10х8 см;</li>
              <li>Сканиране с продължителност от 9.4 до 17.9 s</li>
              <li>Филтрация за намаляване на дозата (до 40%)</li>
              <li>Възможност за експонация на 360&deg; или на 180&deg;</li>
            </ul>
          </template>
          <template v-if="service === 10">
            <p>Изследването чрез конично лъчева компютърна томография (Cone Beam Computed Tomography, СВСТ) познато и като 3D изследване на челюстите и зъбите.</p>
            <p>Чрез СВСТ се получават образи на зъбите и. челюстите във вид на срезове във всички равнини. Върху образите могат да се правят точни измервания на разстояния и ъгли за целите на имплантологията, ортогнатната хирургия, ортодонтията, ендодонтията и навсякъде където те са необходими.</p>
            <p>Удостоения със златен медал на изложението в Kьолн Японски апарат MORITA VERAVIEW X800. 3d и 2d скенер за зъби притежава възможности включващи:</p>
            <ul>
              <li>Уникална рязкост при размер на вокселите до 80 μm;</li>
              <li>Разделителна способност 2.5 LP/mm – за визуализация и на най - фините</li>
              <li>анатомични структури на зъбите;</li>
              <li>Прецизен дигитален образ с минимализиране на металните артефакти;</li>
              <li>СBCT с голям избор на различни обеми на изследване на челюстите (FOV) – от 4х4 см до 10х8 см;</li>
              <li>Сканиране с продължителност от 9.4 до 17.9 s</li>
              <li>Филтрация за намаляване на дозата (до 40%)</li>
              <li>Възможност за експонация на 360&deg; или на 180&deg;</li>
            </ul>
          </template>
          <template v-if="service === 11">
            <p>Удостоения със златен медал на изложението в Kьолн японски апарат MORITA VERAVIEW X800. Притежава възможно най малкия обем за 3D сканиране 4x4 щадящ пациентите от лъчево натоварване с изключително точно блендиране и eспонация 9.4 s.</p>
          </template>
          <template v-if="service === 111">
            <p>Представяме Ви сканиране на TMJ при отворена и затворена уста направен на MORITA VERAVIEW X800. Обема на сканиране и облъчването на пациента както се вижда е ограничен максимално.</p>
            <p>Такова сканиране на темпоро-мандибуларните стави е възможно само с MORITA VERAVIEW X800!</p>
          </template>
          <template v-if="service === 12">
            <p>Този вид снимки на зъбите (направени по бисектрисна на филм или дигитални ) визуализират се от 3 до 4 зъба – от коронката до края на корена, както и костната структура около тях.</p>
            <p>Използват се за диагностика в ендодотията, хирургията и ортодонтията за визуализиране на коренови канали, пародонтални джобове, абсцеси, кисти и др.</p>
          </template>
          <template v-if="service === 13">
            <p>Рентгенография при която образа се получава на рентгенографски филм или дигитална графия по паралелна техника (с филмдържател).</p>
            <p>Филмдържателите спомагат за оптимизиране на образа 1:1 с реалните размери на зъбите, което е изключително полезно при ендодонтско лечение.</p>
          </template>
          <template v-if="service === 14">
            <p>При тази графия се използва специален филмдържател, който позволява лесно и точно позициониране на рентгеновия филм или на дигиталния сензор.</p>
            <p>Този вид рентгенография показва коронките на горните и долните зъби в един образ седна графия се визуализират до 8 зъба.</p>
            <p>Чрез този вид зъбни снимки се диагностицират кариеси дори преди клиничната им изява в страничните части на зъбните коронки, а също и костна загуба при пародонтит и др.</p>
          </template>
          <template v-if="service === 15">
            <p>Правят се около 14 - 18 периапикални зъбни сегментни рентгенографии, за проследяване състоянието на пародонда (венците) и цялостното състояние на съзъбието.</p>
          </template>
          <template v-if="service === 16">
            <p>Оклузални рентгенографии, захапки по Simpson (Долна челюст) – аксиална проекция на долна челюст, визуализират се рентгенпозитивни конкременти в слюнчените жлези, чужди тела, фрактури и др.</p>
          </template>
          <template v-if="service === 17">
            <p>Оклузални рентгенографии, захапки по Belot (Горна челюст) – коса проекция на горна челюст: Използват се за откриване и локализиране на допълнителни или ретинирали зъби, фрактури на челюстта, промени в областта на небцето – кисти, абсцеси и др.</p>
          </template>
          <template v-if="service === 18">
            <p>Рентгенография на шийни прешлени, фасова и профилна проекция: за диагностика на физиологичните извивки на гръбнака в тази област, височината и структурата на телата на прешлените, широчината на дискалните пространства, наличието на шипове, както и за диагностика на фрактурни и огнищни изменения.</p>
          </template>
          <template v-if="service === 19">
            <p>Тази рентгенография се използва в ортодонтията с цел оценка на костна възраст и развитието на скелета</p>
          </template>
          <template v-if="service === 20">
            <p>Профилна ренттгенография на носни кости: използва се за диагностика на фрактури на носните кости</p>
          </template>
        </aside>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  props: ["service"],
  data() {
    return {
      active: 0
    };
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
.service-modal-wrapper {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.2);
  display: flex;
  color: #fff;
  font-family: "Roboto", sans-serif;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.modal {
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 60vw;
  header {
    align-items: center;
    background-color: #043570;
    display: flex;
    height: 100px;
    justify-content: space-between;
    padding: 30px 20px 30px 30px;
    p {
      font-size: 1.25rem;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 2.3rem;
      font-weight: 100;
      line-height: 2.3rem;
    }
    .icon {
      cursor: pointer;
      display: inline-flex;
      padding: 10px;
      @include transition(all $transition $cubic);
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.2);
      }
    }
  }
  main {
    align-items: center;
    background-size: cover;
    display: flex;
    height: calc(85vh - 200px);
    justify-content: flex-end;
    padding: 10px;
    width: 100%;
    &.main-1 {
      background-image: url("../../assets/img/home/services/s1.jpg");
    }
    &.main-2 {
      background-image: url("../../assets/img/home/services/s2.jpg");
    }
    &.main-3 {
      background-image: url("../../assets/img/home/services/s3.jpg");
    }
    &.main-4 {
      background-image: url("../../assets/img/home/services/s4.jpg");
    }
    &.main-5 {
      background-image: url("../../assets/img/home/services/s5.jpg");
    }
    &.main-6 {
      background-image: url("../../assets/img/home/services/s6.jpg");
    }
    &.main-7 {
      background-image: url("../../assets/img/home/services/s7.jpg");
    }
    &.main-8 {
      background-image: url("../../assets/img/home/services/s8.jpg");
    }
    &.main-9 {
      background-image: url("../../assets/img/home/services/s9.jpg");
    }
    &.main-10 {
      background-image: url("../../assets/img/home/services/s10.gif");
    }
    &.main-11 {
      background-image: url("../../assets/img/home/services/s11.jpg");
    }
    &.main-111 {
      background-image: url("../../assets/img/home/services/s111.gif");
    }
    &.main-12 {
      background-image: url("../../assets/img/home/services/s12.jpg");
    }
    &.main-13 {
      background-image: url("../../assets/img/home/services/s13.jpg");
    }
    &.main-14 {
      background-image: url("../../assets/img/home/services/s14.jpg");
    }
    &.main-15 {
      background-image: url("../../assets/img/home/services/s15.jpg");
    }
    &.main-16 {
      background-image: url("../../assets/img/home/services/s16.jpg");
    }
    &.main-17 {
      background-image: url("../../assets/img/home/services/s17.jpg");
    }
    &.main-18 {
      background-image: url("../../assets/img/home/services/s18.jpg");
    }
    &.main-19 {
      background-image: url("../../assets/img/home/services/s19.jpg");
    }
    &.main-20 {
      background-image: url("../../assets/img/home/services/s20.jpg");
    }
    aside {
      background-color: rgba($color: #2a2a2a, $alpha: 0.5);
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 10px;
      width: 220px;
      @include scrollbar(5px, #004393);

      h1 {
        font-size: 2.3rem;
        font-weight: 100;
        line-height: 2.3rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.2rem;
        line-height: 1.7rem;
        margin-bottom: 1rem;
      }
      ul {
        margin: 2rem 0;
        li {
          margin: 12px 0;
          font-size: 1.1rem;
          &::before {
            content: "d";
            font-family: "icons";
            font-weight: 700;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }
      .button {
        background-color: #333c6a;
        border: 1px solid #fff;
        color: #fff;
        margin: 0 auto;
        width: 90%;
      }
    }
  }
}
.close {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 15px;
  top: 40px;
  @include transition(all $transition $cubic);

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.2);
  }
}

.image--mobile {
  display: none;
}
@media screen and (max-width: $xxl) {
  .modal {
    header {
      height: 100px;
      padding: 10px 20px 10px 30px;
      h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
    main {
      height: calc(85vh - 100px);
    }
  }
}

@media screen and (max-width: $s) {
  .modal {
    background-color: #004393;
    height: 100%;
    overflow: auto;
    width: 100%;
    header {
      height: initial;
      padding: 50px 20px 20px;
      .icon {
        position: absolute;
        right: 10px;
        font-size: 1.5rem;
        top: 10px;
      }
    }
    .image--mobile {
      display: block;
      height: auto;
      width: 100%;
    }
    main {
      height: initial;
      padding: 0;
      aside {
        background-color: #004393;
        width: 100%;
        padding: 20px;
      }
    }
  }
  .close {
    font-size: 1.5rem;
    top: 10px;
    right: 10px;
  }
}
</style>