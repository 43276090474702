<template>
  <div class="helpful" id="homeHelpful">
    <div class="texts">
      <h1>Полезно за пациента</h1>
      <p>Резултатът се получава върху носител по избор на пациента, или на лекуващият дентист - с възможност за оптичен диск, или разпечатан върху рентгенов филм чрез висококачествен медицински принтер. Също така резултатът е достъпен и в нашата онлайн система</p>
      <Button class="button--bigger round" @click="$emit('open-app')">
        ОНЛАЙН РЕЗУЛТАТИ
        <span class="icon">d</span>
      </Button>
    </div>
    <div class="uls">
      <ul>
        <li class="icon">x</li>
        <li class="strong">Инструкция при извършване на рентгенография</li>
        <li>Преди извършването и, е необходимо да свалите всички метални предмети (обеци, колиета, зъбни протези, слухови апарати и др), намиращи се в областта на главата и шията</li>
        <li>По време на самото изследване с цел лъчезащита ще ви бъде поставена оловна престилка и/или оловна яка;</li>
        <li>Вашите рентгенографии ще бъдат готови 3 минути след експонацията;</li>
        <li class="icon">x</li>
        <li class="strong">Инструкция при извършване на компютърна томография (3D скенер)</li>
        <li>Изследването става след назначение от лекуващия ви специалист и записване на час следните телефони: 02 / 954 34 98, 0877 850 144, 0884 064 094</li>
        <li>Необходимо е да свалите всички метални предмети (обеци, колиета, зъбни протези, слухови апарати и др), намиращи се в областта на главата и шията;</li>
        <li>По време на самото изследване, с цел лъчезащита, ще ви бъде поставена оловна престилка;</li>
        <li>Оптичният диск и рентгенова плака, с разпечатания чрез медицински принтер образ, ще бъдат готови от 30 мин до максимум 2 ч. след приключване на изследването</li>
        <li class="icon">x</li>
        <li class="strong">Онлайн резултати и личен профил</li>
        <li>
          Дигиталните рентгенови снимки на зъби направени в Медико диагностична лаборатория „3В Рентген“ може да видите чрез
          <a
            @click.prevent="$emit('open-app')"
          >Вашия личен профил</a>&nbsp;в сайта
        </li>
        <li>Данните за достъп ще намерите на картончето, получено при провеждане на изследването</li>
        <li>Консултанти в МДЛ-3В-РЕНТГЕН са д-р Владимир Николов, дм. и д-р Мариан Димитров</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
export default {
  components: {
    Button
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.helpful {
  align-items: center;
  display: flex;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 150px;
  position: relative;
  &::before {
    background-image: url('../../assets/img/home/bg-showcase.jpg');
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    opacity: 0.05;
  }
}

.texts,
ul {
  position: relative;
  z-index: 1;
}

.texts {
  width: 30%;
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 300;
    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
  p {
    color: #0077c7;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  .button {
    background-color: #0077c7;
    color: #fff;
    display: inline-flex;
    justify-content: space-between;
    margin-top: 4rem;
    .icon {
      font-weight: 700;
      margin: 0 0 0 3rem;
      @include transition(all $transition $cubic);
    }
  }
}

.uls {
  display: flex;
  width: 60%;
  padding-right: 100px;
}
ul {
  padding: 20px 0;
  width: 100%;
  li {
    &.icon {
      color: #0077c7;
      font-size: 3rem;
      margin: 2rem 0;
    }
    &.strong {
      color: #183042;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }
    &:not(.strong):not(.icon) {
      margin: 10px 0;
      font-size: 1.2rem;
      &::before {
        content: 'd';
        color: #00c2a9;
        font-family: 'icons';
        font-weight: 700;
        margin-right: 10px;
        vertical-align: middle;
      }
    }
    a {
      color: $accent;
      font-weight: 700;
      text-decoration: underline;
      @include hover();
    }
  }
}

@media screen and (max-width: $xl) {
  .texts {
    h1 {
      font-size: 3rem;
      line-height: 3rem;
    }
    p {
      font-size: 1.1rem;
    }
    .button {
      margin-top: 3rem;
    }
  }
  ul {
    li {
      &.icon {
        font-size: 2.5rem;
        margin: 1.2rem 0;
      }
      &.strong {
        color: #183042;
        font-size: 1.2rem;
        margin-bottom: 1.2rem;
      }
      &:not(.strong):not(.icon) {
        font-size: 1rem;
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .helpful {
    padding-left: 50px;
  }
  .texts {
    h1 {
      font-size: 2rem;
      line-height: 2rem;
    }
    .button {
      margin-top: 2rem;
    }
  }
  ul {
    padding-right: 10px;
  }
}

@media screen and (max-width: $s) {
  .helpful {
    flex-direction: column;
    padding: 20px;
  }
  .texts {
    text-align: center;
    width: 100%;
  }
  .uls {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  ul {
    padding: 0;
    width: 100%;
  }
}

@media screen and (max-width: $xs) {
  .texts {
    h1 {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
    p {
      font-size: 1rem;
    }
  }
}
</style>