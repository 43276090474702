<template>
  <input
    :value="value"
    @input="$emit('input', $event.target.value)"
    @keyup.enter.prevent="$emit('enter')"
    @keyup="$emit('keyup')"
  />
</template>

<script>
export default {
  props: ['value']
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
input {
  border-radius: $input-height / 2;
  border: 1px solid $accent;
  height: $input-height;
  min-height: $input-height;
  outline: 0;
  padding: 0 20px;

  &::placeholder {
    color: $accent;
  }
  &:-moz-placeholder {
    color: $accent;
  }
  &::-moz-placeholder {
    color: $accent;
  }
  &:-ms-input-placeholder {
    color: $accent;
  }
  &::-webkit-input-placeholder {
    color: $accent;
  }
}

@media screen and (max-width: $xl) {
  input {
    border-radius: $xl-input-height / 2;
    height: $xl-input-height;
    min-height: $xl-input-height;
  }
}
</style>