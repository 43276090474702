<template>
  <div class="footer" id="homeFooter">
    <div class="box box--mobile">
      <div class="texts">
        <h1>За нас</h1>
        <p>МДЛ-3В-РЕНТГЕН e създадена през 1998 година от Марияна Георгиева.</p>
      </div>
      <Button class="round" @click="makeInquiry()">
        НАПРАВЕТЕ ЗАПИТВАНЕ
        <span class="icon">d</span>
      </Button>
      <Button class="round facebook" @click="goToFacebook()">
        <span class="icon">2</span>
      </Button>
    </div>
    <header>
      <transition name="fade" mode="in-out">
        <img
          src="@/assets/img/home/about-us-1.jpg"
          alt="About us"
          key="about-image-1"
          v-if="active === 1"
        />
        <img
          src="@/assets/img/home/about-us-2.jpg"
          alt="About us"
          key="about-image-2"
          v-if="active === 2"
        />
        <img
          src="@/assets/img/home/about-us-3.jpg"
          alt="About us"
          key="about-image-3"
          v-if="active === 3"
        />
        <img
          src="@/assets/img/home/about-us-4.jpg"
          alt="About us"
          key="about-image-4"
          v-if="active === 4"
        />
      </transition>
      <div class="box">
        <div class="texts">
          <h1>За нас</h1>
          <p>МДЛ-3В-РЕНТГЕН e създадена през 1998 година от Марияна Георгиева.</p>
        </div>
        <Button class="round" @click="makeInquiry()">
          НАПРАВЕТЕ ЗАПИТВАНЕ
          <span class="icon">d</span>
        </Button>
        <Button class="round facebook" @click="goToFacebook()">
          FACEBOOK СТРАНИЦА
          <span class="icon">2</span>
        </Button>
      </div>
    </header>
    <footer>
      <div class="arrows">
        <span class="icon" @click="prevSlide()">F</span>
        <span class="icon" @click="nextSlide()">d</span>
      </div>
      <div class="box">
        <img class="box__logo" src="@/assets/img/logo-sm.svg" alt="3V Rentgen " />
        <img class="box__logo-text" src="@/assets/img/logo-text.svg" alt="3V Rentgen " />
        <p class="center">
          <strong>&copy; 2020 - 3В Рентген</strong>
        </p>
        <a class="cdots" href="https://cdots.bg/" target="_blank">Решението е разработено от cDots</a>
      </div>
      <ul class="footer-ul">
        <li>
          <p>3В РЕНТГЕН</p>
        </li>
        <li>
          <a class="footer-link" @click="$emit('open-app')">Онлайн система за резултати</a>
        </li>
        <li>
          <a class="footer-link" @click="$emit('register-dentist')">Регистрация - Дентални медици</a>
        </li>
        <!-- <li>
          <a class="footer-link">Направление без регистрация</a>
        </li>-->
        <li>
          <a
            class="footer-link"
            :href="`${files}/confidentiality.pdf`"
            target="_blank"
          >Поверителност на информацията</a>
        </li>
      </ul>
      <ul class="footer-ul">
        <li>
          <p>ПОЛЕЗНИ ВРЪЗКИ</p>
        </li>
        <li>
          <a
            class="footer-link"
            :href="`${files}/radiation1.pdf`"
            target="_blank"
          >Радиацията - какво трябва да знаем</a>
        </li>
        <li>
          <a
            class="footer-link"
            :href="`${files}/radiation2.pdf`"
            target="_blank"
          >Радиацията - полза или риск, обща информация</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="https://www.birpublications.org/"
            target="_blank"
          >Британски институт по радиология</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="http://ncrrp.org/"
            target="_blank"
          >Национален център по радиобиология и радиационна защита</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="http://jada.ada.org/"
            target="_blank"
          >Списание на Американската дентална асоциация (ADA)</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="http://www.mh.government.bg/"
            target="_blank"
          >Министерство на здравеопазването</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="http://www.myesr.org/"
            target="_blank"
          >Европейско дружество по радиология (ESR)</a>
        </li>
        <li>
          <a
            class="footer-link"
            href="http://www.pennwelldentalgroup.com/"
            target="_blank"
          >DentistryIQ - водеща информация за дентални специалисти</a>
        </li>
        <li>
          <a class="footer-link" href="http://www.eurorad.org/" target="_blank">Eurorad by ESR</a>
        </li>
      </ul>
    </footer>
    <InquiryPanel v-if="isInquiryPanelOpen" @close="isInquiryPanelOpen = false" />
  </div>
</template>

<script>
import Button from '@/components/Button';
import InquiryPanel from '../panels/InquiryPanel';
export default {
  components: {
    Button,
    InquiryPanel
  },
  computed: {
    files() {
      return process.env.VUE_APP_FILES_URI;
    }
  },
  data() {
    return {
      active: 1,
      rotation: null,
      isInquiryPanelOpen: false
    };
  },
  methods: {
    makeInquiry() {
      this.isInquiryPanelOpen = true;
    },
    goToFacebook() {
      window.open('https://www.facebook.com/3vrentgen/', '_blank');
    },
    nextSlide() {
      if (this.active < 4) {
        this.active++;
      } else {
        this.active = 1;
      }
    },
    prevSlide() {
      if (this.active > 1) {
        this.active--;
      } else {
        this.active = 4;
      }
    },
    startRotation() {
      this.rotation = setInterval(this.nextSlide, 5000);
    },
    stopRotation() {
      clearInterval(this.rotation);
    }
  },
  mounted() {
    this.startRotation();
  },
  destroyed() {
    this.stopRotation();
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.footer {
  font-family: 'Roboto';
  position: relative;
  header {
    align-items: flex-end;
    display: flex;
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 0 120px;
    height: 44vw;
    img {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .box {
      padding: 20px 20px 120px;
    }
  }
  footer {
    background-color: #002348;
    display: flex;
    // justify-content: center;
    padding: 0 50px 60px 120px;
    position: relative;
    .box {
      padding-bottom: 20px;
      .cdots {
        bottom: -30px;
        color: #fff;
        font-weight: 700;
        left: 50%;
        position: absolute;
        @include transform(translateX(-50%));
        white-space: nowrap;
      }
    }
    .arrows {
      display: flex;
      position: absolute;
      right: 100px;
      top: 20px;
      .icon {
        align-items: center;
        background-color: #0077c7;
        border-radius: 50%;
        color: #fff;
        display: flex;
        font-size: 1.2rem;
        height: 52px;
        justify-content: center;
        width: 52px;
        @include noselect();
        @include hover();
        &:first-child {
          margin-right: 10px;
          background-color: #9599b3;
        }
      }
    }
  }
}
.box {
  background-color: rgba($color: $blue, $alpha: 0.82);
  color: #fff;
  margin-right: 150px;
  position: relative;
  min-width: 340px;
  width: 340px;
  z-index: 1;
  .texts {
    margin-bottom: 1rem;
    h1 {
      font-size: 3rem;
      font-weight: 300;
      margin: 1.5rem 0 2.5rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
  > p {
    font-size: 1.1rem;
  }
  .button {
    color: #2a5584;
    justify-content: space-between;
    margin-bottom: 1rem;
    .icon {
      margin: 0;
      font-size: 1.5rem;
    }
    &.facebook {
      background-color: #4267b2;
      border: 1px solid #fff;
      color: #fff;
    }
  }
}
.box__logo {
  @include transform(scale(1.15) translateY(-20%));
  width: 100%;
}
.box__logo-text {
  width: calc(100% - 20px);
  margin: 0 10px 10px;
}
.footer-ul {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  padding-top: 50px;
  &:first-of-type {
    margin-right: 150px;
  }
  li {
    margin-bottom: 1.5rem;
    &:first-of-type {
      font-weight: 700;
      margin-bottom: 3rem;
    }
  }
}
.footer-link {
  color: #4da1ff;
  cursor: pointer;
}
a:hover {
  color: #00ffcb;
  text-decoration: underline;
}
.panel-wrapper {
  z-index: 10;
}
.box--mobile {
  display: none;
}
@media screen and (max-width: $xl) {
  .box {
    margin-right: 100px;
  }
  .footer-ul {
    &:first-of-type {
      margin-right: 100px;
    }
  }
}
@media screen and (max-width: $l) {
  .box {
    min-width: 300px;
    width: 300px;
  }
}
@media screen and (max-width: $m) {
  .box {
    margin-right: 75px;
  }
  .footer-ul {
    &:first-of-type {
      margin-right: 75px;
    }
  }
}
@media screen and (max-width: $sm) {
  .box {
    margin-right: 50px;
    min-width: 260px;
    width: 260px;
    .texts {
      margin-bottom: 1rem;
      h1 {
        font-size: 2rem;
        font-weight: 300;
        margin: 1rem 0;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
  .footer header .box {
    padding-bottom: 80px;
  }

  .footer {
    header,
    footer {
      padding-left: 30px;
    }
    footer {
      .arrows {
        right: 20px;
      }
    }
  }
  .footer-ul {
    &:first-of-type {
      margin-right: 50px;
    }
  }
}
@media screen and (max-width: $s) {
  .footer {
    .box--mobile {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0;
      padding: 20px;
      width: 100%;
      .texts {
        width: 100%;
        text-align: center;
      }
      .button {
        &:not(.facebook) {
          .icon {
            margin-left: 10px;
          }
        }
        &.facebook {
          margin-left: 10px;
          .icon {
            font-size: 2rem;
          }
        }
      }
    }
    header {
      flex-direction: column-reverse;
      padding: 0;
      .box {
        display: none;
      }
    }
    footer {
      flex-direction: column;
      .arrows {
        top: -26px;
      }
      .box {
        display: none;
      }
    }
  }
}
</style>