import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
import Snotify from 'vue-snotify'
import {
  ApiService
} from './common/api.service'

Vue.config.productionTip = false

Vue.prototype.$apiService = ApiService

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;

Vue.filter('formatDate', (date) => {
  return date ? moment(date).format('DD/MM/YYYY') : null
})
Vue.filter('formatDateFull', (date) => {
  return date ? moment(date).format('DD/MM/YYYY - HH:mm') : null
})

// Ensure we checked auth before each page load.
router.beforeEach((to, from, next) => {
  store.dispatch('checkAuth').then(next);
});

const options = {
  toast: {
    timeout: 5000,
    showProgressBar: false,
    position: 'leftBottom'
  }
}
Vue.use(Snotify, options)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')