<template>
  <Panel :padding="true" :closeMobile="true" width="fixed" @close-panel="$emit('close')">
    <!-- LOGIN -->
    <div class="panel__content" v-if="forgottenPassword" key="panel__content--0">
      <div class="top top--forgotten">
        <img class="img-login" src="@/assets/img/home/panel-login.svg" alt="Login" />
      </div>
      <form @submit.prevent>
        <Input v-model="user.email" type="email" placeholder="e-mail" @enter="reset" />
        <Button class="button--bigger round accent" @click="reset">ИЗПРАЩАНЕ</Button>
      </form>
      <a
        class="confidentiality"
        :href="`${files}/confidentiality.pdf`"
        target="_blank"
      >ПОВЕРИТЕЛНОСТ НА ИНФОРМАЦИЯТА</a>
    </div>
    <!-- LOGIN -->
    <div class="panel__content" v-else-if="loginMode" key="panel__content--1">
      <div class="top">
        <img class="img-login" src="@/assets/img/home/panel-login.svg" alt="Login" />
        <div class="texts">
          <h3 class="center">Онлайн система за резултати и направления</h3>
          <!-- <p class="center strong">Вход за пациенти, дентални лекари, служители</p> -->
        </div>
      </div>
      <form @submit.prevent>
        <Input
          v-model="user.username"
          type="text"
          placeholder="Потребителско име / E-mail"
          @enter="login"
        />
        <Input v-model="user.password" type="password" placeholder="Парола" @enter="login" />
        <Button class="button--bigger round accent" @click="login">ВХОД</Button>
        <p class="center link" @click="forgottenPassword = true">ЗАБРАВЕНА ПАРОЛА</p>
        <p key="login-message-info" class="center">Или се регистрирайте, ако сте Дентален лекар</p>
        <Button
          class="button--bigger round bluish"
          @click="toggleLoginMode"
        >РЕГИСТРАЦИЯ ДЕНТАЛЕН ЛЕКАР</Button>
        <!-- <router-link
          class="confidentiality"
          to="/app/noreg-examination"
        >ИЗДАВАНЕ НА НАПРАВЛЕНИЕ БЕЗ РЕГИСТРАЦИЯ</router-link>-->
        <a
          class="confidentiality"
          :href="`${files}/confidentiality.pdf`"
          target="_blank"
        >ПОВЕРИТЕЛНОСТ НА ИНФОРМАЦИЯТА</a>
      </form>
    </div>
    <!-- REGISTER -->
    <div class="panel__content" v-else key="panel__content--2">
      <div class="top">
        <img class="img-register" src="@/assets/img/home/panel-register.svg" alt="Login" />
        <div class="texts">
          <h3 class="center">Регистрация дентален лекар</h3>
          <p
            class="center"
          >От онлайн системата ще може да издавате електронни направления за вашите пациенти и да преглеждате историята на техните снимки.</p>
        </div>
      </div>
      <form @submit.prevent>
        <Input v-model="user.name" type="text" placeholder="Име, Фамилия" @enter="register" />
        <Input v-model="user.email" type="text" placeholder="e-mail" @enter="register" />
        <Input
          v-model="user.username"
          type="text"
          placeholder="Потребителско име"
          @keyup="formatUsername()"
          @enter="register"
        />
        <Input v-model="user.password" type="password" placeholder="Парола" @enter="register" />
        <Input
          v-model="user.location"
          type="text"
          placeholder="Адрес на практика/клиника"
          @enter="register"
        />
        <Input v-model="user.phone" type="number" placeholder="телефон" @enter="register" />
        <Button class="button--bigger round bluish" @click="register">РЕГИСТРАЦИЯ</Button>
        <p key="register-message-info" class="center">Ако вече имате регистрация</p>
        <Button class="button--bigger round accent" @click="toggleLoginMode">ВЛЕЗТЕ С ВАШИЯ ПРОФИЛ</Button>
        <a
          class="confidentiality"
          :href="`${files}/confidentiality.pdf`"
          target="_blank"
        >ПОВЕРИТЕЛНОСТ НА ИНФОРМАЦИЯТА</a>
      </form>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel.vue';
import Input from '@/components/Input.vue';
import Button from '@/components/Button.vue';
export default {
  props: ['registerMode'],
  components: {
    Panel,
    Input,
    Button
  },
  computed: {
    files() {
      return process.env.VUE_APP_FILES_URI;
    }
  },
  data() {
    return {
      loginMode: true,
      forgottenPassword: false,
      user: {
        role: 'dentist',
        username: '',
        email: '',
        password: '',
        location: '',
        name: '',
        phone: ''
      }
    };
  },
  methods: {
    formatUsername() {
      if (this.user.username.match(/[^A-Za-z0-9._]/g)) {
        this.$snotify.warning(
          'За потребителско име използвайте само английски символи, числа, точка или долна черта.'
        );
      }
      this.user.username = this.user.username
        .replace(/[^A-Za-z0-9._]/g, '')
        .toLowerCase();
    },
    toggleLoginMode() {
      this.loginMode = !this.loginMode;

      if (!this.loginMode) {
        this.formatUsername();
      }
    },
    login() {
      if (this.isValid()) {
        this.$store
          .dispatch('login', this.user)
          .then(() => {
            this.$router.push('/app');
          })
          .catch(err => {
            this.$snotify.error(err.response.data.message);
          });
      }
    },
    register() {
      if (this.isValid()) {
        this.$apiService
          .post('/auth/register', {
            user: JSON.stringify(this.user)
          })
          .then(() => {
            this.login();
          })
          .catch(err => {
            this.$snotify.error(err.response.data.message);
          });
      }
    },
    reset() {
      if (!this.user.email) {
        this.$snotify.error('Не добавили e-mail адрес!');
        return;
      }
      this.$apiService
        .post('/auth/forgotten-password', {
          email: this.user.email
        })
        .then(() => {
          this.$snotify.success(
            'Ще получите инструкции на посочения e-mail адрес!'
          );
          this.$emit('close');
        })
        .catch(err => {
          this.$snotify.error(err.response.data.message);
        });
    },
    isValid() {
      if (!this.user.username) {
        this.$snotify.error('Трябва да въведете потребителско име!');
        return false;
      }
      if (!this.user.password) {
        this.$snotify.error('Трябва да въведете парола!');
        return false;
      }
      if (!this.loginMode) {
        if (!this.user.location) {
          this.$snotify.error('Трябва да въведете адрес на практика/клиника!');
          return false;
        }
        if (!this.user.name) {
          this.$snotify.error('Трябва да въведете име и фамилия!');
          return false;
        }
        if (!this.user.phone) {
          this.$snotify.error('Трябва да въведете телефон!');
          return false;
        }
        if (!this.user.email) {
          this.$snotify.error('Трябва да въведете e-mail адрес!');
          return false;
        }
      }
      return true;
    }
  },
  mounted() {
    if (this.registerMode) {
      this.toggleLoginMode();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/transitions.scss';
.panel__content {
  .top {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 10px;
    &:not(.top--forgotten) {
      height: 100%;
    }
    .img-login {
      width: 280px;
    }
    .img-register {
      width: 180px;
    }
    .texts {
      h3,
      p:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

form {
  display: flex;
  flex-direction: column;

  & > :nth-child(n):not(:last-child) {
    margin-bottom: 15px;
  }
}

.confidentiality,
.link {
  cursor: pointer;
  color: #0077c7;
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  @include hover();
}

@media screen and (max-width: $xl) {
  .panel__content {
    .top {
      .img-login {
        width: 220px;
      }
      .img-register {
        width: 140px;
      }
    }
  }
}
@media screen and (max-width: $l) {
  .panel__content {
    .top {
      .img-login {
        width: 135px;
      }
      .img-register {
        width: 120px;
      }
    }
  }
}
@media screen and (max-width: $s) {
  .panel__content {
    .top {
      .img-login {
        width: 75px;
      }
      .img-register {
        width: 60px;
      }
    }
  }
}
</style>