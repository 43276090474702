<template>
  <div class="home">
    <Header
      :openAuthPanel="openAuthPanel"
      :openAuthPanelAndRegister="openAuthPanelAndRegister"
      @auth-panel-closed="openAuthPanel = false, openAuthPanelAndRegister = false"
    />
    <Showcase @open-app="openAuthPanel = true"/>
    <Services />
    <Locations />
    <!-- in the header there's a wathcher which watches the openAuthPanel prop -->
    <Helpful @open-app="openAuthPanel = true" />
    <Footer @open-app="openAuthPanel = true" @register-dentist="openAuthPanelAndRegister = true" />
  </div>
</template>

<script>
import Header from './Header';
import Showcase from './Showcase';
import Services from './Services';
import Locations from './Locations';
import Helpful from './Helpful';
import Footer from './Footer';
export default {
  name: 'Home',
  components: {
    Header,
    Showcase,
    Services,
    Locations,
    Helpful,
    Footer
  },
  data() {
    return {
      openAuthPanel: false,
      openAuthPanelAndRegister: false
    };
  }
};
</script>