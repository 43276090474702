<template>
  <div class="button" :class="{ 'button--disabled': disabled }" @click.prevent.stop="click">
    <slot />
  </div>
</template>

<script>
export default {
  props: ['disabled'],
  methods: {
    click() {
      if (!this.disabled) {
        this.$emit('click');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.button {
  align-items: center;
  background-color: #fff;
  color: $color;
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 700;
  height: $button-height;
  justify-content: center;
  min-height: $button-height;
  min-width: 1px;
  overflow: hidden;
  padding: 1rem 1.2rem;
  text-align: center;
  @include noselect();
  @include transition(all $transition $cubic);
  .icon {
    font-weight: 400;
    line-height: 0.9rem;
    margin-right: 1rem;
  }
  &.delete {
    .icon {
      margin: 0;
    }
  }
  &.round {
    border-radius: $button-height / 2;
    @include hover();
  }
  &.button--bigger {
    font-size: 1rem;
    height: $input-height;
    min-height: $input-height;
    padding: 1rem 2rem;
    .icon {
      line-height: 1rem;
    }
    &.round {
      border-radius: $input-height / 2;
    }
  }
  &.accent {
    background-color: $accent;
  }
  &.red {
    background-color: #a80000;
    color: #fff;
    &:hover {
      background-color: #940000;
    }
  }
  &.bluish {
    background-color: #add4ff;
  }
  &.blue {
    background-color: $blue;
    color: #fff;
    &:hover {
      background-color: $blue--button-hover;
    }
  }
  &.green {
    background-color: #00c2a9;
    color: #fff;
    &:hover {
      background-color: rgb(0, 172, 149);
    }
  }
  &.grey {
    background-color: #5b5b5b;
    color: #fff;
    &:hover {
      background-color: #303030;
    }
  }
  &.dark {
    background-color: #223432;
    &:hover {
      background-color: #000;
    }
    color: #fff;
  }
  &.orange {
    background-color: #c77700;
    &:hover {
      background-color: rgb(163, 98, 0);
    }
    color: #fff;
  }
  &.button--width {
    width: 280px;
  }
  &.button--panel {
    justify-content: flex-start;
    min-width: $panel-width--fixed;
    padding: 0 20px;
  }

  &.button--disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      background-color: red;
    }
  }
}

@media screen and (max-width: $xl) {
  .button,
  .button.button--bigger {
    min-height: $xl-button-height;
    height: $xl-button-height;
    &.button--panel {
      min-width: $xl-panel-width--fixed;
    }
  }
}
@media screen and (max-width: $s) {
  .button {
    &.button--mobile-circle {
      border-radius: 50%;
      height: $xl-button-height;
      min-height: $xl-button-height;
      min-width: $xl-button-height;
      padding: 0;
      width: $xl-button-height;
      span:not(.icon) {
        display: none;
      }
      .icon {
        font-size: 20px;
        margin: 0;
      }
    }
  }
}
</style>