<template>
  <Panel :heightless="true">
    <!-- LOGIN -->
    <div class="panel__content">
      <span class="icon close" @click="$emit('close')">L</span>
      <header>
        <p class="center strong">Направете запитване</p>
        <p class="center">до Медико диагностична лаборатория 3В-РЕНТГЕН</p>
      </header>
      <main>
        <div class="row">
          <div class="input" :class="{ 'input--invalid': invalidName }">
            <input
              v-model="inquiry.name"
              type="text"
              placeholder="Вашите имена..."
              @keyup="invalidName = false"
              id="inquiryName"
            />
            <label for="inquiryName">Вашите имена</label>
          </div>
          <div class="input" :class="{ 'input--invalid': invalidEmail }">
            <input
              v-model="inquiry.email"
              type="email"
              placeholder="Вашият e-mail"
              @keyup="invalidEmail = false"
              id="inquiryEmail"
            />
            <label for="inquiryEmail">e-mail, на който да върнем отговор</label>
          </div>
        </div>
        <div class="row">
          <div class="input" :class="{ 'input--invalid': invalidAbout }">
            <input
              v-model="inquiry.about"
              type="text"
              placeholder="Относно..."
              @keyup="invalidAbout = false"
              id="inquiryAbout"
            />
            <label for="inquiryAbout">Тема на запитването</label>
          </div>
        </div>
        <div class="row">
          <textarea
            :class="{ 'textarea--invalid': invalidMessage }"
            v-model="inquiry.message"
            cols="30"
            rows="10"
            placeholder="Вашето съобщение..."
            @keyup="invalidMessage = false"
          ></textarea>
        </div>
      </main>
      <footer>
        <p>
          <span class="icon">d</span>
          Запознайте се с нашето съобщение за
          <a
            class="accent confidentiality"
            :href="`${files}/confidentiality.pdf`"
            target="_blank"
          >Поверителност на информацията</a>
        </p>
        <Button class="dark" @click="send()">
          <span class="icon">d</span>
          ИЗПРАТИ
        </Button>
      </footer>
      <transition name="fade">
        <div class="success" v-if="sent">
          <h1>Запитването е изпратено успешно!</h1>
          <p>Oчаквайте отговор от нас.</p>
        </div>
      </transition>
    </div>
  </Panel>
</template>

<script>
import { mapGetters } from 'vuex';
import Panel from '@/components/Panel.vue';
import Button from '@/components/Button.vue';
export default {
  components: {
    Panel,
    Button
  },
  computed: {
    ...mapGetters(['currentUser']),
    files() {
      return process.env.VUE_APP_FILES_URI;
    }
  },
  data() {
    return {
      sent: false,
      invalidName: false,
      invalidEmail: false,
      invalidAbout: false,
      invalidMessage: false,
      inquiry: {
        name: '',
        email: '',
        about: '',
        message: ''
      }
    };
  },
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    isValid() {
      if (!this.inquiry.name) {
        this.invalidName = true;
      }
      if (!this.validateEmail(this.inquiry.email)) {
        this.invalidEmail = true;
      }
      if (!this.inquiry.about) {
        this.invalidAbout = true;
      }
      if (!this.inquiry.message) {
        this.invalidMessage = true;
      }

      if (
        this.invalidName ||
        this.invalidEmail ||
        this.invalidAbout ||
        this.invalidMessage
      ) {
        return false;
      } else {
        return true;
      }
    },
    send() {
      if (this.isValid()) {
        this.$apiService.post('/inquiries', this.inquiry).then(() => {
          this.sent = true;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.close {
  cursor: pointer;
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 10px;
  @include noselect();
  @include transition(all $transition $cubic);
  z-index: 1;
  &:hover {
    background-color: rgba($color: #000, $alpha: 0.1);
  }
}
.panel__content {
  padding: 40px;
  width: 50vw;
  position: relative;
  header {
    @include noselect();
    p {
      font-size: 1.2rem;
      &:first-child {
        font-size: 1.5rem;
      }
    }
  }
  main {
    padding: 40px 0 20px;
  }

  footer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    > p {
      align-items: center;
      display: flex;
      .icon {
        color: $accent;
        font-weight: 700;
        margin-right: 10px;
      }
      .confidentiality {
        font-weight: 700;
        text-align: center;
        text-decoration: underline;
        margin-left: 5px;
      }
    }
  }
}

.success {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  h1 {
    margin-bottom: 10px;
  }
}

.row {
  display: flex;
  .input {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    &:not(:first-child) {
      margin-left: 20px;
    }
    input {
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #000;
      height: 36px;
      outline: 0;
      text-align: center;
      width: 100%;
      margin-bottom: 5px;
      @include transition(all $transition $cubic);
      &::placeholder {
        font-weight: 700;
        @include transition(all $transition $cubic);
      }
    }

    &.input--invalid {
      input {
        border-bottom: 1px solid $error;

        &::placeholder {
          color: $error;
        }
      }
    }
    label {
      text-align: center;
      font-style: italic;
    }
  }
  textarea {
    border: 1px solid #000;
    font-family: 'Roboto', sans-serif;
    outline: 0;
    margin-top: 40px;
    padding: 10px;
    resize: none;
    width: 100%;
    @include transition(all $transition $cubic);
    &::placeholder {
      font-weight: 700;
      @include transition(all $transition $cubic);
    }
    &.textarea--invalid {
      border: 1px solid $error;
      &::placeholder {
        color: $error;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .close {
    right: 5px;
    top: 5px;
  }
  .panel__content {
    overflow: auto;
    padding: 20px;
    max-height: 90vh;
    .row {
      flex-direction: column;
      .input {
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
    main {
      padding: 10px 0;
    }
    textarea {
      height: 100px;
    }
    footer {
      flex-direction: column-reverse;
      > p {
        display: block;
        margin-top: 10px;
        text-align: center;
        .icon {
          vertical-align: middle;
        }
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .panel__content {
    width: 90vw;
  }
}
</style>