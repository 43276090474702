var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"panel"}},[(_vm.showPanel)?_c('div',{staticClass:"panel-wrapper",class:{ 
      'panel-wrapper--with-close': _vm.close,
      'panel-wrapper--with-tabs': _vm.tabsSpace,
      'panel-wrapper--absolute': _vm.absolute,
      'panel-wrapper--print': _vm.print,
    }},[_c('div',{staticClass:"panel",class:{ 
        'panel--padding': _vm.padding,
        'panel--fixed': _vm.width === 'fixed',
        'heightless': _vm.heightless,
        'no-max': _vm.nomax
      }},[(_vm.close || _vm.closeMobile)?_c('div',{staticClass:"close",class:{ 'close--mobile': _vm.closeMobile },on:{"click":function($event){_vm.$emit('close-panel'), _vm.$store.dispatch('error', null)}}},[_c('span',{staticClass:"icon"},[_vm._v("F")])]):_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_vm._t("default")],2)],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }