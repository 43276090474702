<template>
  <div class="locations" id="homeLocations">
    <div class="map">
      <transition name="fade">
        <img
          class="map__img"
          src="@/assets/img/home/map1.jpg"
          alt="Map"
          key="map-1"
          v-if="active === 1"
        />
        <img
          class="map__img"
          src="@/assets/img/home/map2.jpg"
          alt="Map"
          key="map-2"
          v-if="active === 2"
        />
        <img
          class="map__img"
          src="@/assets/img/home/map3.jpg"
          alt="Map"
          key="map-3"
          v-if="active === 3"
        />
      </transition>
      <!-- address box -->
      <div
        class="map__address"
        :class="{
        'map__address--2': active === 2,
        'map__address--3': active === 3
      }"
      >
        <div class="map__address-content">
          <transition name="fade" mode="out-in">
            <div v-if="active === 1" key="address-box-1">
              <p>
                <strong>МДЛ-3В-РЕНТГЕН</strong>
              </p>
              <p>ул.“Люлин планина” №40</p>
              <hr />
              <p>
                <span class="icon">X</span>
                <a href="tel:+35929543498">02 954 34 98</a>,
                <a href="tel:+359884064094">0884 064 094</a>,
                <a href="tel:+359877851303">0877 851 303</a>
              </p>
              <p v-if="isDST">
                <span class="icon">4</span>Лятно работно време: 08:00-19:30 ч.
              </p>
              <p v-else>
                <span class="icon">4</span>Зимно работно време: 08:00-19:30 ч.
              </p>
              <p>
                <span class="icon">4</span>Събота: 09:00 - 17:00 ч.
              </p>
              <p>
                <span class="icon">4</span>Неделя: 10:00 - 17:00 ч.
              </p>
            </div>
            <div v-if="active === 2" key="address-box-2">
              <p>
                <strong>МДЛ-3В-РЕНТГЕН</strong>
              </p>
              <p>ул. "Цар Симеон" № 21</p>
              <hr />
              <p>
                <span class="icon">X</span>
                <a href="tel:+359877850144">0877 850 144</a>,
                <a href="tel:+359882356289">0882 356 289</a>,
                <a href="tel:+359024887290">02 488 7290</a>
              </p>
              <p>
                <span class="icon">4</span>Работно време: 08:00-19:30 ч.
              </p>
              <p>
                <span class="icon">4</span>Почивни дни: събота и неделя
              </p>
            </div>
            <div v-if="active === 3" key="address-box-3">
              <p>
                <strong>МДЛ-3В-РЕНТГЕН</strong>
              </p>
              <p>17 ДКЦ бул. Евлоги Георгиев 108,</p>
              <p>ет.3, к-т 313</p>
              <hr />
              <p>
                <span class="icon">X</span><a href="tel:+359877851333">0877 851 333</a>
              </p>
              <p>
                <span class="icon">4</span>Работно време: 08:00-19:00 ч.
              </p>
              <p>
                <span class="icon">4</span>Почивни дни: събота и неделя
              </p>
            </div>
          </transition>
        </div>
        <p>
          <a
            href="https://goo.gl/maps/ZeqzP1r5s3yztVE68"
            target="_blank"
            v-if="active === 1"
          >ВИЖ В GOOGLE MAPS</a>
          <a
            href="https://goo.gl/maps/k61P2k3gAEftJgnc7"
            target="_blank"
            v-if="active === 2"
          >ВИЖ В GOOGLE MAPS</a>
          <a
            href="https://goo.gl/maps/MAJLGucbBhopyv1V6"
            target="_blank"
            v-if="active === 3"
          >ВИЖ В GOOGLE MAPS</a>
        </p>
      </div>
    </div>
    <div class="texts">
      <h1>Локации и оборудване</h1>
      <p
        class="accent"
      >Медико-диагностична лаборатория 3В-РЕНТГЕН разполага с три локации и седем апарата за извършване на рентгенографии, висококачествени и прецизни ортопантомографии (панорамни), телерентгенографии и 3Д компютърни томографии във всички обеми.</p>
      <a
        class="small-button"
        :class="{'active' : active === 1}"
        @click="active = 1"
      >ЦЕНТРАЛНА ЛАБОРАТОРИЯ</a>
      <!-- MOBILE ADDRESS 1 -->
      <div class="map__address--mobile" :class="{ 'map__address--mobile--active': active === 1 }">
        <div class="map__address-content">
          <p>
            <strong>МДЛ-3В-РЕНТГЕН</strong>
          </p>
          <p>ул.“Люлин планина” №40</p>
          <hr />
          <p>
            <span class="icon">X</span>
            <a href="tel:+35929543498">02 954 34 98</a>,
            <a href="tel:+359884064094">0884 064 094</a>,
            <a href="tel:+359877851303">0877 851 303</a>
          </p>
          <p v-if="isDST">
            <span class="icon">4</span>Лятно работно време: 08:00-19:30 ч.
          </p>
          <p v-else>
            <span class="icon">4</span>Зимно работно време: 08:00-19:30 ч.
          </p>
          <p>
            <span class="icon">4</span>Събота: 09:00 - 19:00 ч.
          </p>
          <p>
            <span class="icon">4</span>Неделя: 10:00 - 17:00 ч.
          </p>
        </div>
        <p>
          <a href="https://goo.gl/maps/ZeqzP1r5s3yztVE68" target="_blank">ВИЖ В GOOGLE MAPS</a>
        </p>
      </div>
      <a
        class="small-button"
        :class="{'active' : active === 2}"
        @click="active = 2"
      >УЛ. “ЦАР СИМЕОН” № 21</a>
      <div class="map__address--mobile" :class="{ 'map__address--mobile--active': active === 2 }">
        <div class="map__address-content">
          <div key="address-box-2">
            <p>
              <strong>МДЛ-3В-РЕНТГЕН</strong>
            </p>
            <p>ул. "Цар Симеон" № 21</p>
            <hr />
            <p>
              <span class="icon">X</span>
              <a href="tel:+359877850144">0877 850 144</a>,
              <a href="tel:+359882356289">0882 356 289</a>,
              <a href="tel:+359024887290">02 488 7290</a>
            </p>
            <p>
              <span class="icon">4</span>Работно време: 08:00-19:30 ч.
            </p>
            <p>
              <span class="icon">4</span>Почивни дни: събота и неделя
            </p>
          </div>
        </div>
        <p>
          <a href="https://goo.gl/maps/k61P2k3gAEftJgnc7" target="_blank">ВИЖ В GOOGLE MAPS</a>
        </p>
      </div>
      <!-- <a class="small-button" :class="{'active' : active === 3}" @click="active = 3">ФИЛИАЛ 17 ДКЦ</a>
      <div class="map__address--mobile" :class="{ 'map__address--mobile--active': active === 3 }">
        <div class="map__address-content">
          <div>
            <p>
              <strong>МДЛ-3В-РЕНТГЕН</strong>
            </p>
            <p>17 ДКЦ бул. Евлоги Георгиев 108,</p>
            <p>ет.3, к-т 313</p>
            <hr />
            <p>
              <span class="icon">X</span><a href="tel:+359877851333">0877 851 333</a>
            </p>
            <p>
              <span class="icon">4</span>Работно време: 08:00-19:00 ч.
            </p>
            <p>
              <span class="icon">4</span>Почивни дни: събота и неделя
            </p>
          </div>
        </div>
        <p>
          <a href="https://goo.gl/maps/MAJLGucbBhopyv1V6" target="_blank">ВИЖ В GOOGLE MAPS</a>
        </p>
      </div> -->
    </div>
    <div class="gear-cards">
      <!-- 1 -->
      <div class="gear-card">
        <transition name="fade">
          <div class="texts" key="texts1" v-if="active === 1" @click="selected = 1">
            <p>ОРТОПАНТОМОГРАФ</p>
            <h1>VistaPano S Ceph от Dürr Dental</h1>
          </div>
          <div class="texts" key="texts4" v-if="active === 2" @click="selected = 4">
            <p>3D СКЕНЕР И ОРТОПАНТОМОГРАФ</p>
            <h1>Morita Veraview X800 (CBCT и 2D)</h1>
          </div>
          <div class="texts" key="texts6" v-if="active === 3" @click="selected = 6">
            <p>ОРТОПАНТОМОГРАФ</p>
            <h1>Planmeca Proline XC</h1>
          </div>
        </transition>
        <transition name="fade">
          <img
            src="../../assets/img/home/gear/a1.png"
            key="a1"
            v-if="active === 1"
            @click="selected = 1"
          />
          <img
            src="../../assets/img/home/gear/a4.png"
            key="a4"
            v-if="active === 2"
            @click="selected = 4"
          />
          <img
            src="../../assets/img/home/gear/a6.png"
            key="a6"
            v-if="active === 3"
            @click="selected = 6"
          />
        </transition>
      </div>
      <!-- 2 -->
      <div class="gear-card">
        <transition name="fade">
          <div class="texts" key="texts2" v-if="active === 1" @click="selected = 2">
            <p>CBCT 3D</p>
            <h1>Planmeca Viso™ G5</h1>
          </div>
          <div class="texts" key="texts5" v-if="active === 2" @click="selected = 5">
            <p>ЗЪБЕН КУГЕЛ</p>
            <h1>XMIND UNITY - ACTEON</h1>
          </div>
          <div class="texts" key="texts7" v-if="active === 3" @click="selected = 7">
            <p>ЗЪБЕН КУГЕЛ</p>
            <h1>Planmeca ProX</h1>
          </div>
        </transition>
        <transition name="fade">
          <img
            src="../../assets/img/home/gear/a2.png"
            key="a2"
            v-if="active === 1"
            @click="selected = 2"
          />
          <img
            src="../../assets/img/home/gear/a5.png"
            key="a4"
            v-if="active === 2"
            @click="selected = 5"
          />
          <img
            src="../../assets/img/home/gear/a7.png"
            key="a7"
            v-if="active === 3"
            @click="selected = 7"
          />
        </transition>
      </div>
      <!-- 3 -->
      <transition name="fade">
        <div class="gear-card" v-if="active === 1" @click="selected = 3">
          <div class="texts">
            <p>ЗЪБЕН КУГЕЛ</p>
            <h1>VistaIntra DC от Dürr Dental</h1>
          </div>
          <img src="../../assets/img/home/gear/a3.png" alt />
        </div>
      </transition>
    </div>
    <transition name="fade">
      <GearModal v-if="selected" :gear="selected" @close="selected = null" />
    </transition>
  </div>
</template>

<script>
import GearModal from "./GearModal";
export default {
  components: {
    GearModal
  },
  data() {
    return {
      active: 1,
      selected: null
    };
  },
  computed: {
    isDST() {
      return this.$moment().isDST();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
.locations {
  background-color: #183042;
  color: #fff;
  display: flex;
  font-family: "Roboto";
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 150px 150px 40px;
  position: relative;
  .content {
    position: relative;
    z-index: 1;
  }
}

.map {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 65%;
  .map__img {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .map__address {
    position: absolute;
    right: 40%;
    top: 39%;
    @include transition(all $transition $cubic);
    &.map__address--2 {
      right: 24%;
      top: 38%;
    }
    &.map__address--3 {
      right: 28%;
      top: 44%;
    }
    > p {
      color: #00ffcb;
      font-size: 1.1rem;
      margin-top: 5px;
      text-align: center;
      a {
        color: #00ffcb;
        @include hover();
      }
    }
    .map__address-content {
      border: 1px solid #00ffcb;
      color: #00ffcb;
      font-size: 1.1rem;
      height: 161px;
      padding: 0 5px;
      text-align: center;
      width: 314px;
      @include transition(all $transition $cubic);
      hr {
        background-color: #00ffcb;
        border: 0;
        height: 1px;
        width: 80%;
      }
      p {
        margin: 6px 0;
        text-align: center;
        &:first-child {
          font-size: 1.2rem;
        }
        .icon {
          vertical-align: middle;
          margin-right: 5px;
        }
        a {
          color: $accent;
          font-weight: 700;
          text-decoration: none;
          @include hover();
        }
      }
    }
  }
}

.locations > .texts {
  position: relative;
  width: 35%;
  z-index: 1;
  h1 {
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 3rem;
  }
  .small-button {
    align-items: center;
    border: 1px solid $accent;
    border-radius: 18px;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 700;
    display: flex;
    height: 36px;
    justify-content: center;
    margin-top: 1rem;
    width: 300px;
    @include transition(all $transition $cubic);
    &:hover {
      background-color: rgba($color: $accent, $alpha: 0.5);
    }
    &.active {
      background-color: $accent;
    }
  }
}
.gear-cards {
  display: flex;
  position: relative;
  z-index: 1;
  margin-top: 20px;
}
.gear-card {
  width: calc(33.33% - 16.66px);
  height: 27vh;
  position: relative;

  @include hover();
  &:nth-child(1) {
    background-color: #004393;
  }
  &:nth-child(2) {
    background-color: #005792;
    margin-left: 20px;
  }
  &:nth-child(3) {
    background-color: #006c91;
    margin-left: 20px;
  }
  .texts {
    height: 100%;
    left: 0;
    padding-left: 20px;
    padding-top: 20px;
    position: absolute;
    top: 0;
    width: 60%;
    p {
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
    h1 {
      font-weight: 100;
      font-size: 3rem;
      line-height: 3rem;
    }
  }
  > img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
  }
}

.map__address--mobile {
  display: none;
}

@media screen and (max-width: $xxl) {
  .locations {
    padding: 100px 150px 40px;
  }
  .locations > .texts {
    h1 {
      font-size: 3rem;
      line-height: 3rem;
    }
    p {
      font-size: 1.1rem;
    }
  }
  .gear-card {
    .texts {
      p {
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }
      h1 {
        font-weight: 100;
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
  .gear-card {
    height: 25vh;
  }
}

@media screen and (max-width: $sm) {
  .locations {
    padding: 50px 50px 40px;
    > .texts {
      h1 {
        font-size: 2rem;
        line-height: 2rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
  }
  .gear-cards {
    margin-top: 40px;
  }
  .gear-card {
    height: 20vh;
    .texts {
      p {
        font-size: 1.1rem;
        margin-bottom: 1rem;
      }
      h1 {
        font-weight: 100;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
  .map {
    .map__address {
      right: 30%;
      &.map__address--2 {
        right: 20%;
      }
      &.map__address--3 {
        right: 20%;
      }

      .map__address-content {
        font-size: 1rem;
        height: 141px;
        width: 280px;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .gear-card {
    height: 20vh;
    .texts {
      p {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
      h1 {
        font-size: 1.3rem;
        line-height: 1.3rem;
      }
    }
  }
  .map {
    .map__address {
      right: 16%;
      top: 40%;
      &.map__address--2 {
        right: 10%;
        top: 39%;
      }
      &.map__address--3 {
        right: 10%;
      }
    }
  }
}
@media screen and (max-width: $s) {
  .map {
    display: none;
  }
  .locations {
    padding: 20px;
    > .texts {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 100%;
    }
  }
  .gear-cards {
    overflow: auto;
    width: 100%;
    @include scrollbar(5px, #004393);
  }
  .gear-card {
    width: 40vw;
    min-width: 40vw;
    height: 30vh;
    .texts {
      padding: 5px;
      width: 100%;
    }
    > img {
      bottom: 0;
      height: initial;
      top: initial;
      @include transform(translateX(-25%));
      width: 100%;
    }
  }

  .locations {
    .map__address--mobile {
      display: block;

      max-height: 0px;
      overflow: hidden;
      @include transition(all $transition $cubic);

      &.map__address--mobile--active {
        max-height: 181px;
        margin-top: 10px;
      }
      > p {
        color: #00ffcb;
        font-size: 1.1rem;
        margin-top: 5px;
        margin-bottom: 0;
        text-align: center;
        a {
          color: #00ffcb;
          @include hover();
        }
      }
      .map__address-content {
        border: 1px solid #00ffcb;
        color: #00ffcb;
        font-size: 1.1rem;
        height: 161px;
        padding: 0 5px;
        text-align: center;
        width: 314px;
        @include transition(all $transition $cubic);
        hr {
          background-color: #00ffcb;
          border: 0;
          height: 1px;
          width: 80%;
        }
        p {
          margin: 6px 0;
          text-align: center;
          &:first-child {
            font-size: 1.2rem;
          }
          .icon {
            vertical-align: middle;
            margin-right: 5px;
          }
          a {
            color: $accent;
            font-weight: 700;
            text-decoration: none;
            @include hover();
          }
        }
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .locations {
    > .texts {
      h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
      p {
        font-size: 1rem;
      }
    }
  }

  .gear-card {
    width: 60vw;
    min-width: 60vw;
  }
  .locations {
    .map__address--mobile {
      .map__address-content {
        width: 300px;
      }
    }
  }
}
</style>