import {
  ApiService,
  AuthService
} from '@/common/api.service'


const state = {
  errors: null,
  user: {
    role: localStorage.getItem('role')
  },
  isAuthenticated: !!localStorage.getItem('token')
}

const getters = {
  authErrors: state => state.errors,
  currentUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated
}

const actions = {
  // setError(context, error) {
  //   this.commit('setError', error);
  // },
  checkAuth(context) {
    context.commit('purgeError');
    if (localStorage.getItem('token')) {
      AuthService.setAuthHeader();
      ApiService.get("/auth/validation")
        .then((response) => {
          if (response.data.user) {
            context.commit('setAuth', response.data);
          } else {
            context.commit('purgeAuth');
          }
        })
        .catch(() => {
          context.commit('purgeAuth');
        });
    } else {
      context.commit('purgeAuth');
    }
  },
  login(context, user) {
    return new Promise((res, rej) => {
      ApiService.post('/auth/login', user)
        .then((response) => {
          context.commit('setAuth', response.data)
          AuthService.setAuthHeader();
          res(response)
        })
        .catch((err) => {
          rej(err)
          // context.commit('setError', err.response.data.message);
        });
    });
  },
  logout(context) {
    context.commit('purgeAuth')
  }
}

const mutations = {
  // setError(state, err) {
  //   state.errors = err;
  // },
  // purgeError(state) {
  //   state.errors = null;
  // },
  setAuth(state, data) {
    localStorage.setItem('token', data.token)
    localStorage.setItem('role', data.user.role)
    state.user = data.user;
    state.isAuthenticated = true;
    state.errors = null;
  },
  purgeAuth(state) {
    localStorage.removeItem('token')
    localStorage.removeItem('role')
    state.user = {};
    state.isAuthenticated = false;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};