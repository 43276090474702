<template>
  <div
    class="home__header"
    :class="{ 'home__header--panel': isAuthPanelOpen, 'home__header--scrolled': y > 0 }"
  >
    <router-link to="/" class="header__logo">
      <img src="@/assets/img/logo.svg" alt="3V Rö" />
    </router-link>
    <ul class="home__header__ul">
      <li>
        <div class="link" @click="goToSection('homeServices')">
          <span class="icon">a</span>
          <p>УСЛУГИ</p>
          <p>цени и резултати</p>
        </div>
      </li>
      <li>
        <div class="link" @click="goToSection('homeLocations')">
          <span class="icon">M</span>
          <p>ЛОКАЦИИ</p>
          <p>и оборудване</p>
        </div>
      </li>
      <li>
        <div class="link" @click="goToSection('homeHelpful')">
          <span class="icon">B</span>
          <p>ПОЛЕЗНО</p>
          <p>за пациента</p>
        </div>
      </li>
      <li>
        <div class="link" @click="goToSection('homeFooter')">
          <span class="icon">E</span>
          <p>ЗА НАС</p>
          <p>и запитвания</p>
        </div>
      </li>
      <li>
        <Button
          @click="toggleResults()"
          class="round button--bigger button--width"
          :class="{ 'button--panel': isAuthPanelOpen}"
        >
          <transition name="slide-left" mode="out-in">
            <span v-if="!isAuthPanelOpen" key="panel-toggle-text">
              <span>ВХОД/РЕГИСТРАЦИЯ</span>
              <span>ОНЛАЙН РЕЗУЛТАТИ</span>
            </span>
            <span class="icon" v-else key="panel-toggle-icon">d</span>
          </transition>
        </Button>
      </li>
    </ul>
    <AuthPanel v-if="isAuthPanelOpen" :registerMode="registerMode" @close="toggleResults" />
    <MessagePanel />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/Button.vue';
import AuthPanel from '@/views/panels/AuthPanel';
import MessagePanel from './MessagePanel';
export default {
  props: ['openAuthPanel', 'openAuthPanelAndRegister'],
  components: {
    Button,
    AuthPanel,
    MessagePanel
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  watch: {
    openAuthPanel(to) {
      if (to) {
        this.toggleResults();
      }
    },
    openAuthPanelAndRegister(to) {
      if (to) {
        this.registerMode = true;
        this.toggleResults();
      }
    }
  },
  data() {
    return {
      isAuthPanelOpen: false,
      registerMode: false,
      y: window.scrollY
    };
  },
  methods: {
    goToSection(s) {
      const el = document.getElementById(s);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    toggleResults() {
      if (this.isAuthPanelOpen) {
        this.isAuthPanelOpen = false;
        this.registerMode = false;
        this.$emit('auth-panel-closed');
      } else {
        if (this.currentUser._id) {
          this.$router.push('/app');
        } else {
          this.isAuthPanelOpen = true;
        }
      }
    },
    handleScroll() {
      this.y = window.scrollY;
    }
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll);

    this.$nextTick(() => {
      if (this.$route.query.login === 'true') {
        this.toggleResults();
      }
    });
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.home__header {
  background: transparent;
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: space-between;
  left: 0;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  @include transition(all $transition $cubic);
  z-index: 10;

  &::before {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00c2a9+0,2a5584+100 */
    background: #00c2a9; /* Old browsers */
    background: -moz-linear-gradient(
      -45deg,
      #00c2a9 0%,
      #2a5584 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      -45deg,
      #00c2a9 0%,
      #2a5584 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      135deg,
      #00c2a9 0%,
      #2a5584 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00c2a9', endColorstr='#2a5584',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    content: '';
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
    @include transition(all $transition $cubic);
  }
  &.home__header--panel {
    padding-right: 50px;
  }
}
.header__logo {
  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  @include hover();
  img {
    display: block;
    height: 100%;
    margin-right: 20px;
  }
}

.home__header__ul {
  display: flex;
  li {
    align-items: center;
    display: inline-flex;
    margin-left: 10px;
  }
  .link {
    color: #fff;
    position: relative;
    padding-left: 2rem;
    padding-right: 1rem;
    @include hover();
    .icon {
      font-size: 4rem;
      left: 0;
      opacity: 0.25;
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
    }
    p {
      font-size: 0.9rem;
      white-space: nowrap;
      &:first-of-type {
        font-weight: 700;
      }
    }
  }
  .button {
    span:not(.icon) {
      display: flex;
      flex-direction: column;
      &:first-child {
        color: #000;
        font-size: 1.1rem;
      }
      &:last-child {
        color: #7a7a7a;
        font-size: 0.9rem;
      }
    }
  }
}

.home__header--scrolled {
  height: 98px;
  &::before {
    opacity: 1;
  }
  .header__logo {
    @include transform(scale(1.3) translateX(10%) translateY(20%));
  }
}

@media screen and (max-width: $xl) {
  .home__header:not(.home__header--scrolled) {
    height: 160px;
  }
}

@media screen and (max-width: $l) {
  .home__header:not(.home__header--scrolled) {
    height: 140px;
  }
  .home__header__ul {
    li {
      margin-left: 8px;
    }
    .link {
      padding-left: 1.5rem;
      padding-right: 0;
      .icon {
        font-size: 3rem;
      }
      p {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .home__header:not(.home__header--scrolled) {
    height: $sm-header-height;
  }
  .home__header {
    bottom: 0;
    height: $sm-header-height;
    padding: 10px;
    top: initial;
    width: 100%;
    &.home__header--panel {
      padding-right: 10px;
    }
  }
  .header__logo {
    display: none;
  }
  .home__header__ul {
    width: 100%;
    justify-content: space-around;
  }
  .home__header__ul {
    li:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: $xs) {
  .home__header__ul {
    justify-content: space-between;
    li {
      width: calc(25% - 6px);
      .link {
        padding-left: 1rem;
        .icon {
          font-size: 2rem;
        }
        p {
          font-size: 0.8rem;
        }
        p:last-of-type {
          display: none;
        }
      }
    }
  }
}
</style>