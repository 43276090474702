<template>
  <transition name="panel">
    <div
      v-if="showPanel"
      class="panel-wrapper"
      :class="{ 
        'panel-wrapper--with-close': close,
        'panel-wrapper--with-tabs': tabsSpace,
        'panel-wrapper--absolute': absolute,
        'panel-wrapper--print': print,
      }"
    >
      <div
        class="panel"
        :class="{ 
          'panel--padding': padding,
          'panel--fixed': width === 'fixed',
          'heightless': heightless,
          'no-max': nomax
        }"
      >
        <div
          class="close"
          :class="{ 'close--mobile': closeMobile }"
          v-if="close || closeMobile"
          @click="$emit('close-panel'), $store.dispatch('error', null)"
        >
          <span class="icon">F</span>
        </div>
        <transition name="fade" mode="out-in">
          <slot></slot>
        </transition>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: [
    'absolute', // if true panel is not with fixed, but with absolute positioning
    'close', // if true close button is showed and black transparent background is added
    'closeMobile', // if true close button is showed on mobile
    'heightless',
    'padding',
    'nomax', // no max width;
    'tabsSpace', // align the panel on top and make space in the bottom for the tabs
    'print', // enable print styles
    'width'
  ],
  data() {
    return {
      showPanel: false
    };
  },
  mounted() {
    // for the transition to work
    this.showPanel = true;
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@import '@/scss/transitions.scss';
.panel-wrapper {
  align-items: center;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  right: 0;
  position: fixed;
  top: 0;
  z-index: 5;
  @include transition(all $transition $cubic);
  &.panel-wrapper--with-close {
    background-color: rgba($color: #000, $alpha: 0.5);
    width: 100%;
  }
  &.panel-wrapper--with-tabs {
    align-items: flex-start;
    padding: 10px 0;
    .panel {
      height: calc(100% - #{$user-tabs-height});
    }
  }
  &.panel-wrapper--absolute {
    position: absolute;
    .panel {
      -webkit-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
      -moz-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
      box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
      height: 80%;
    }
  }
}
.panel {
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  max-height: 100%;
  position: relative;
  width: initial;
  &:not(.heightless) {
    height: calc(100% - 20px);
  }
  &:not(.no-max) {
    max-width: 50vw;
    // width: 550px;
  }

  .panel__content {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    justify-content: space-between;
    @include scrollbar(5px, #dadada);
  }

  &.panel--fixed {
    width: $panel-width--fixed;
  }
  &.panel--padding {
    padding: 30px;
    .panel__content {
      padding: 20px;
    }
  }

  .close {
    align-items: center;
    background-color: $white--bluish;
    border-bottom-left-radius: 50%;
    border-top-left-radius: 50%;
    color: $color;
    display: flex;
    height: $input-height;
    justify-content: center;
    left: -$input-height;
    width: $input-height;
    top: $input-height / 2;
    position: absolute;

    &.close--mobile {
      display: none;
    }

    .icon {
      align-items: center;
      display: flex;
      font-weight: 700;
      height: 100%;
      justify-content: center;
      width: 100%;
      @include hover();
      @include transform(scaleX(-1));
    }
  }
}

.panel-enter-active,
.panel-leave-active {
  @include transition(all $transition $cubic);

  .panel {
    @include transition(all $transition $cubic);
  }
}

.panel-enter,
.panel-leave-to {
  opacity: 0;

  .panel {
    @include transform(translateX(100%));
  }
}

@media screen and (max-width: $xl) {
  .panel {
    &.panel--fixed {
      width: $xl-panel-width--fixed;
    }
    .close {
      height: $xl-input-height;
      left: -$xl-input-height;
      width: $xl-input-height;
      top: $xl-input-height / 2;
    }
  }
}

@media screen and (max-width: $sm) {
  .panel {
    &:not(.no-max) {
      max-width: calc(100vw - 50px);
    }
    .close {
      &.close--mobile {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: $s) {
  .panel {
    &.panel--padding {
      padding: 10px;
      .panel__content {
        padding: 10px;
      }
    }
  }
}

@media print {
  .panel-wrapper {
    align-items: flex-start;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
    background-color: transparent !important;
    &.panel-wrapper--print {
      padding: 0;
      position: relative;
      width: 100%;
      .panel {
        border-radius: 0;
        height: initial;
        max-height: initial;
        width: 100%;
        .close {
          display: none;
        }
      }
      .panel__content {
        border-radius: 0;
      }
    }
  }
}
</style>