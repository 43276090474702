var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home__showcase"},[_c('div',{staticClass:"background"}),_c('div',{staticClass:"left"},[_vm._m(0),_vm._m(1),_c('ul',{staticClass:"steps"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('li',{staticClass:"li--button"},[_c('Button',{staticClass:"button--bigger round button--width",on:{"click":function($event){return _vm.$emit('open-app')}}},[_c('span',[_c('span',[_vm._v("ВХОД/РЕГИСТРАЦИЯ")]),_c('span',[_vm._v("ОНЛАЙН РЕЗУЛТАТИ")])])])],1)])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo--mobile"},[_c('img',{attrs:{"src":require("@/assets/img/logo.svg"),"alt":"3V-Rentgen"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texts"},[_c('h1',[_vm._v("Рентгенографии на зъби, ортопантомографии (панорамни), телерентгенографии, компютърна томография (CBCT 3D)")]),_c('p',{staticClass:"accent"},[_vm._v("В лабораторията на ул. “Люлин планина” № 40 е инсталиран нов модерен щадящ пациентите немски томограф за панорамни зъбни снимки - VistaPano S Ceph на Dürr Dental")]),_c('br'),_c('p',{staticClass:"accent"},[_vm._v("На Ваше разположение са специалистите в Денталната образна диагностика - доц. д-р Владимир Николов д.м., доайен в денталната рентгенология и д-р Мариан Димитров")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"accent"},[_vm._v(" 1 "),_c('span',{staticClass:"icon"},[_vm._v("w")])]),_c('div',{staticClass:"texts"},[_c('h1',[_vm._v("Снимка")]),_c('p',{staticClass:"accent"},[_vm._v("от 4.1 до 7.0 секунди")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"accent"},[_vm._v(" 2 "),_c('span',{staticClass:"icon"},[_vm._v("w")])]),_c('div',{staticClass:"texts"},[_c('h1',[_vm._v("Резултат")]),_c('p',{staticClass:"accent"},[_vm._v("от 3 до 5 минути")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('p',{staticClass:"accent"},[_vm._v(" 3 "),_c('span',{staticClass:"icon"},[_vm._v("w")])]),_c('div',{staticClass:"texts"},[_c('h1',[_vm._v("е-Картон")]),_c('p',{staticClass:"accent"},[_vm._v("с история на пациента")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require("@/assets/img/home/hero.png"),"alt":""}})])
}]

export { render, staticRenderFns }