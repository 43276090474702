<template>
  <div class="gear-modal-wrapper">
    <div class="modal">
      <header>
        <div class="texts">
          <template v-if="gear === 1">
            <p>
              ОРТОПАНТОМОГРАФ - УЛ.“ЛЮЛИН ПЛАНИНА” №40 (ЦЕНТРАЛНА ЛАБОРАТОРИЯ)
            </p>
            <h1>VistaPano S Ceph от Dürr Dental</h1>
          </template>
          <template v-if="gear === 2">
            <p>CBCT 3D - УЛ.“ЛЮЛИН ПЛАНИНА” №40 (ЦЕНТРАЛНА ЛАБОРАТОРИЯ)</p>
            <h1>Planmeca Viso™ G5</h1>
          </template>
          <template v-if="gear === 3">
            <p>ЗЪБЕН КУГЕЛ - УЛ.“ЛЮЛИН ПЛАНИНА” №40 (ЦЕНТРАЛНА ЛАБОРАТОРИЯ)</p>
            <h1>VistaIntra от Dürr Dental</h1>
          </template>
          <template v-if="gear === 4">
            <p>3D СКЕНЕР И ОРТОПАНТОМОГРАФ - УЛ. ЦАР СИМЕОН 21</p>
            <h1>MORITA Veraview X800</h1>
          </template>
          <template v-if="gear === 5">
            <p>ЗЪБЕН КУГЕЛ - УЛ. ЦАР СИМЕОН 21</p>
            <h1>XMIND UNITY - ACTEON</h1>
          </template>
          <template v-if="gear === 6">
            <p>
              ОРТОПАНТОМОГРАФ - 17 ДКЦ БУЛ.“ЕВЛОГИ ГЕОРГИЕВ” №108, ЕТ.3, К-Т 313
            </p>
            <h1>Planmeca Proline XC</h1>
          </template>
          <template v-if="gear === 7">
            <p>
              ЗЪБЕН КУГЕЛ - 17 ДКЦ БУЛ.“ЕВЛОГИ ГЕОРГИЕВ” №108, ЕТ.3, К-Т 313
            </p>
            <h1>Planmeca ProX</h1>
          </template>
        </div>
        <span class="icon" @click="$emit('close')">L</span>
      </header>
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m1.jpg"
        alt="Image 1"
        v-if="gear === 1"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m2.jpg"
        alt="Image 2"
        v-if="gear === 2"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m3.jpg"
        alt="Image 3"
        v-if="gear === 3"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m4.jpg"
        alt="Image 4"
        v-if="gear === 4"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m5.jpg"
        alt="Image 5"
        v-if="gear === 5"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m6.jpg"
        alt="Image 6"
        v-if="gear === 6"
      />
      <img
        class="image--mobile"
        src="@/assets/img/home/gear/m7.jpg"
        alt="Image 7"
        v-if="gear === 7"
      />
      <main :class="`main-${gear}`">
        <aside>
          <template v-if="gear === 1">
            <h1>Нов модерен щадящ пациентите немски томограф</h1>
            <p>
              VistaPano S от Dürr Dental поставя нов стандарт за рязкост на
              екстраоралните изображения. 2D панорамният рентгенов апарат също е
              привлекателен поради лекотата си на работа и добре обмислен
              работен процес, който се поддържа от иновативен 7” сензорен
              дисплей.
            </p>
            <ul>
              <li>Много бързо сканиране за 4,1 секунди</li>
              <li>Отлично качество и рязкост на изображението</li>
              <li>Ниска доза радиация</li>
              <li>
                Без промяна на сензора, вградени са 2 сензора от висок клас
              </li>
              <li>
                Подходящ за млади пациенти - заради комбинацията от ниска
                рентгенова доза и кратко време за сканиране
              </li>
            </ul>
          </template>
          <template v-else-if="gear === 2">
            <h1>
              Иновативния <strong>Planmeca Viso™ G5</strong>, е монтиран в
              лабораторията ни на <strong>Люлин планина № 40</strong>
            </h1>
            <p>
              <strong>Planmeca Viso™ G5 има</strong> голям избор на различни
              обеми за изследване (FOV) - от Ø30 x 30
              <strong>(ендодонтски)</strong> до Ø200 x 170 обем (за цялата
              лицева област)
            </p>
            <ul>
              <li>
                <strong>Voxel size: 75 μm*, 150 μm, 300 μm, 450 μm </strong>
              </li>
              <li>
                Възможността за промяна на вокселите ни дава възможност за
                промяна яркостта и контраста. Само
                <strong>Planmeca Viso™ G5</strong> има интелигентен алгоритъм за
                корекция на движение <strong>Planmeca CALM™</strong>, той
                елиминира необходимостта от повторения, като отменя ефектите от
                движението на пациента. Където грешки не се допускат!
              </li>
              <li>
                <strong>Planmeca Viso™ G5</strong> предлага уникалния протокол
                за изображения <strong>Planmeca Ultra Low Dose™</strong>. Това
                позволява на денталните лекари да получават 3D изображения при
                значително по-ниски ефективни дози за пациента, без
                статистическо намаляване на качеството на изображението.
              </li>
              <li>
                <strong>Planmeca Viso™ G5</strong> е единствения по рода си
                най-нов скенер в България, който има значително по-малко
                облъчване от на другите лаборатории.
              </li>
            </ul>
          </template>
          <template v-else-if="gear === 3">
            <h1>
              Систематично рентгеново решение - перфектно по функция и дизайн
            </h1>
            <p>
              С VistaIntra сега ви предлагаме и рентгенови генератори за отлични
              вътреорални изображения. VistaIntra впечатлява със своя примерен
              ергономичен дизайн и е перфектно съчетан с плаки Dürr Dental.
            </p>
            <ul>
              <li>Тънък за лесно и прецизно позициониране</li>
              <li>Лесно и бързо заснемане</li>
              <li>Перфектни дигитални резултати, както и на плака</li>
              <li>Непроменливо излъчване на радиацията</li>
              <li>DC генератор с фокусно петно от 0,4 мм</li>
              <li>Корекция на снимката след експозиция</li>
            </ul>
          </template>
          <template v-else-if="gear === 4">
            <h1>
              Morita e съвременен японски апарат, правещ уникални 2D и 3D
              изображения
            </h1>
            <p>
              Удостоения със златен медал на изложението в Kьолн 2017 г.,
              апарата притежава следните възможности:
            </p>
            <ul>
              <li>Уникална рязкост при размер на вокселите до 80 μm</li>
              <li>Визуализация и на най-фините анатомични структури</li>
              <li>
                Прецизен дигитален образ с минимализиране на металните артефакти
              </li>
              <li>
                СBCT с голям избор на различни обеми на изслед-ване на челюстите
                (FOV) – от 4х4 см до 10х8 см;
              </li>
              <li>Опция за промяна наклона на рентгеновия лъч до 5&deg;</li>
              <li>
                Ниска доза – намаляне на дозата рентген до 40% Експонации на
                360&deg;/180&deg;, сканиране с от 9.4 до 17.9s
              </li>
              <li>
                Различни режими вкл. за деца и възрастни, за ТМС,
                ортопантомография тип bite – wing и др.;
              </li>
              <li>Телерентгенографии с избор на различни размери</li>
            </ul>
          </template>
          <template v-else-if="gear === 5">
            <h1>
              Надеждна технология на изпълнението, намаляваща изложението на
              радиация
            </h1>
            <p>
              Със своя елегантен стил, съчетан с патентованата ACE технология,
              X-MIND UNITY извежда стандарта на рентгеновите апарати на ново
              ниво. Перфектната точност и защитата на пациента са това, до което
              никоя друга рентгенова система не успя да достигне досега.
            </p>
            <ul>
              <li>Остри и контрастни изображения - фокус до 0.4 mm</li>
              <li>
                До 52% по-малко радиация с ACE технологията - апарата позволява
                да се стартира и спира генератора, като по този начин се избягва
                всякакъв риск от прекомерно излагане на пациента
              </li>
              <li>
                Безопасност чрез проследяване - дозата, получена от пациента, се
                появява на екрана след всяка експозиция
              </li>
            </ul>
          </template>
          <template v-else-if="gear === 6">
            <h1>
              Надеждна технология на изпълнението, намаляваща изложението на
              радиация
            </h1>
            <p>
              Панорамната и цефалометрична система Planmeca Proline XC съчетава
              иновативни технически решения, лесно позициониране на пациента и
              интуитивен интерфейс, с изключително качествени панорамни и
              цефалометрични рентгенографии.
            </p>
            <ul>
              <li>Лесен и удобен достъп и позициониране на пациента</li>
              <li>Моторизирани държачи за удобно позициониране на пациента</li>
              <li>
                Тройна лазерна система за точно подравняване спрямо анатомията
                на пациента
              </li>
              <li>
                С най-съвременните директни цифрови изображения на изображението
                се предлага за диагностициране веднага след експозицията
              </li>
            </ul>
          </template>
          <template v-else-if="gear === 7">
            <h1>
              Ефективна 2D образна диагностика, гъвкавост при позиционирането
            </h1>
            <p>
              Усъвършенстваният интраорален рентгенов апарат на Planmeca ProX
              осигурява лесно и прецизно позициониране, лесен процес на
              изобразяване и изображения с най-високо качество и висока
              разделителна способност.
            </p>
            <ul>
              <li>
                Бърз и лесен за използване: предварително програмирани бързи
                настройки, практичен дизайн
              </li>
              <li>
                Намалена доза на радиация с до 25% в сравнение с
                конвенционалните променливи генератори
              </li>
              <li>Изключително добър и равномерен контраст на изображението</li>
              <li>Дигитален експорт на снимките</li>
            </ul>
          </template>
          <Button class="button--bigger round" @click="gallery = true"
            >ГАЛЕРИЯ - РЕНТГЕНОГРАФИИ</Button
          >
        </aside>
      </main>
      <transition name="fade">
        <div class="gallery" v-if="gallery">
          <span class="icon close" @click="gallery = false">L</span>
          <p class="gallery-name">ГАЛЕРИЯ - РЕНТГЕНОГРАФИИ</p>
          <div class="preview">
            <img :src="images[gear][active]" alt />
          </div>
          <div class="thumbs">
            <div
              class="thumb"
              v-for="(img, i) in images[gear]"
              :key="`img-${i}`"
              @click="active = i"
            >
              <img :src="img" :alt="`Image ${i + 1}`" />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
export default {
  components: { Button },
  props: ["gear"],
  data() {
    return {
      gallery: false,
      active: 0,
      images: {
        1: [
          require("../../assets/img/home/gear/g1/1.jpg"),
          require("../../assets/img/home/gear/g1/2.png"),
          require("../../assets/img/home/gear/g1/3.jpg"),
          require("../../assets/img/home/gear/g1/4.jpg"),
          require("../../assets/img/home/gear/g1/5.jpg"),
        ],
        2: [
          require("../../assets/img/home/gear/g2/1.jpg"),
          require("../../assets/img/home/gear/g2/2.jpg"),
          require("../../assets/img/home/gear/g2/3.jpg"),
          require("../../assets/img/home/gear/g2/4.jpg"),
          require("../../assets/img/home/gear/g2/5.jpg"),
          require("../../assets/img/home/gear/g2/6.jpg"),
          require("../../assets/img/home/gear/g2/7.gif"),
          require("../../assets/img/home/gear/g2/8.jpg"),
          require("../../assets/img/home/gear/g2/9.jpg"),
        ],
        3: [
          require("../../assets/img/home/gear/zk/1.png"),
          require("../../assets/img/home/gear/zk/2.jpg"),
          require("../../assets/img/home/gear/zk/3.jpg"),
          require("../../assets/img/home/gear/zk/4.jpg"),
          require("../../assets/img/home/gear/zk/5.jpg"),
          require("../../assets/img/home/gear/zk/6.jpg"),
        ],
        4: [
          require("../../assets/img/home/gear/g4/2.jpg"),
          require("../../assets/img/home/gear/g4/3.jpg"),
          require("../../assets/img/home/gear/g4/4.jpg"),
          require("../../assets/img/home/gear/g4/5.png"),
          require("../../assets/img/home/gear/g4/6.png"),
          require("../../assets/img/home/gear/g4/7.jpg"),
          require("../../assets/img/home/gear/g4/8.jpg"),
          require("../../assets/img/home/gear/g4/9.jpg"),
        ],
        5: [
          require("../../assets/img/home/gear/zk/1.png"),
          require("../../assets/img/home/gear/zk/2.jpg"),
          require("../../assets/img/home/gear/zk/3.jpg"),
          require("../../assets/img/home/gear/zk/4.jpg"),
          require("../../assets/img/home/gear/zk/5.jpg"),
          require("../../assets/img/home/gear/zk/6.jpg"),
        ],
        6: [
          require("../../assets/img/home/gear/g6/1.jpg"),
          require("../../assets/img/home/gear/g6/2.png"),
          require("../../assets/img/home/gear/g6/3.png"),
        ],
        7: [
          require("../../assets/img/home/gear/zk/1.png"),
          require("../../assets/img/home/gear/zk/2.jpg"),
          require("../../assets/img/home/gear/zk/3.jpg"),
          require("../../assets/img/home/gear/zk/4.jpg"),
          require("../../assets/img/home/gear/zk/5.jpg"),
          require("../../assets/img/home/gear/zk/6.jpg"),
        ],
      },
    };
  },
  mounted() {
    document.body.classList.add("noscroll");
  },
  destroyed() {
    document.body.classList.remove("noscroll");
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";
@import "@/scss/transitions.scss";
.gear-modal-wrapper {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.2);
  display: flex;
  font-family: "Roboto", sans-serif;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.modal {
  background-color: #fff;
  -webkit-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 70vw;
  header {
    align-items: center;
    background-color: #004393;
    display: flex;
    height: 130px;
    justify-content: space-between;
    padding: 30px 20px 30px 30px;
    p {
      font-size: 1.25rem;
      margin-bottom: 10px;
    }
    h1 {
      font-size: 3rem;
      font-weight: 100;
      line-height: 3rem;
    }
    .icon {
      cursor: pointer;
      display: inline-flex;
      padding: 10px;
      @include transition(all $transition $cubic);
      &:hover {
        background-color: rgba($color: #fff, $alpha: 0.2);
      }
    }
  }
  main {
    align-items: center;
    background-size: cover;
    display: flex;
    height: calc(85vh - 130px);
    justify-content: flex-end;
    padding: 10px;
    width: 100%;
    &.main-1 {
      background-image: url("../../assets/img/home/gear/m1.jpg");
    }
    &.main-2 {
      background-image: url("../../assets/img/home/gear/m2.jpg");
    }
    &.main-3 {
      background-image: url("../../assets/img/home/gear/m3.jpg");
    }
    &.main-4 {
      background-image: url("../../assets/img/home/gear/m4.jpg");
    }
    &.main-5 {
      background-image: url("../../assets/img/home/gear/m5.jpg");
    }
    &.main-6 {
      background-image: url("../../assets/img/home/gear/m6.jpg");
    }
    &.main-7 {
      background-image: url("../../assets/img/home/gear/m7.jpg");
    }
    aside {
      background-color: rgba($color: #004393, $alpha: 0.85);
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: auto;
      padding: 20px;
      width: 460px;
      @include scrollbar(5px, #004393);

      h1 {
        font-size: 2.3rem;
        font-weight: 100;
        line-height: 2.3rem;
        margin-bottom: 2rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 1.5rem;
      }
      ul {
        margin: 2rem 0;
        li {
          margin: 12px 0;
          font-size: 1.1rem;
          &::before {
            content: "d";
            font-family: "icons";
            font-weight: 700;
            margin-right: 10px;
            vertical-align: middle;
          }
        }
      }
      .button {
        background-color: #333c6a;
        border: 1px solid #fff;
        color: #fff;
        margin: 0 auto;
        width: 90%;
      }
    }
  }
}

.gallery {
  background-color: #333c6a;
  border: 1px solid #fff;
  display: flex;
  height: 100%;
  left: 0;
  top: 0;
  padding: 35px 50px 50px 25px;
  position: absolute;
  width: 100%;
}

.close {
  cursor: pointer;
  padding: 10px;
  position: absolute;
  right: 15px;
  top: 40px;
  @include transition(all $transition $cubic);

  &:hover {
    background-color: rgba($color: #fff, $alpha: 0.2);
  }
}

.gallery-name {
  bottom: 10px;
  font-weight: 700;
  left: 25px;
  position: absolute;
}

.preview {
  width: 100%;
  img {
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    width: 100%;
  }
}

.thumbs {
  display: flex;
  flex-direction: column;
  width: 130px;
  @include transform(translateX(-20%) scale(1.03));
  .thumb {
    border: 2px solid #fff;
    height: calc(20% - 64.4px);
    width: 100%;
    @include hover();
    &:not(:last-child) {
      margin-bottom: 18px;
    }
    img {
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      width: 100%;
    }
  }
}

.image--mobile {
  display: none;
}

@media screen and (max-width: $xxl) {
  .modal {
    header {
      height: 100px;
      padding: 10px 20px 10px 30px;
      h1 {
        font-size: 2.5rem;
        line-height: 2.5rem;
      }
      p {
        font-size: 1.1rem;
      }
    }
    main {
      height: calc(85vh - 100px);
    }
  }
}
@media screen and (max-width: $xl) {
  .thumbs {
    .thumb {
      height: calc(20% - 8px);

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .preview {
    img {
      height: 100%;
      background-position: left;
      width: 100%;
    }
  }
}

@media screen and (max-width: $s) {
  .modal {
    height: 100%;
    overflow: auto;
    width: 100%;
    header {
      height: initial;
      padding: 50px 20px 20px;
      .icon {
        position: absolute;
        right: 10px;
        font-size: 1.5rem;
        top: 10px;
      }
    }
    .image--mobile {
      display: block;
      height: auto;
      width: 100%;
    }
    main {
      height: initial;
      padding: 0;
      aside {
        background-color: #004393;
        width: 100%;
      }
    }
  }
  .close {
    font-size: 1.5rem;
    top: 10px;
    right: 10px;
  }

  .gallery {
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 5px;
    position: fixed;
  }
  .preview {
    /* height: 50vh; */
  }
  .thumbs {
    flex-direction: row;
    height: 10vh;
    justify-content: center;
    @include transform(translateY(-20%));
    width: 100%;
    .thumb {
      height: 100%;
      width: calc(18% - 8px);
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
  .gallery-name {
    bottom: initial;
    left: 50%;
    top: 25px;
    @include transform(translateX(-50%));
  }
}
</style>