<template>
  <div id="app">
    <div class="wrapper">
      <router-view />
    </div>
    <vue-snotify />
  </div>
</template>

<script>
export default {
  watch: {
    $route() {
      this.$store.dispatch('error', null);
    }
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
@import '~vue-snotify/styles/material.css';

@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Italic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icons';
  src: url('./assets/fonts/icons.eot');
  src: url('./assets/fonts/icons.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/icons.woff') format('woff'),
    url('./assets/fonts/icons.ttf') format('truetype'),
    url('./assets/fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-home:before {
  content: '\0041';
}
.icon-images:before {
  content: '\0042';
}
.icon-users:before {
  content: '\0043';
}
.icon-messages:before {
  content: '\0044';
}
.icon-about:before {
  content: '\0045';
}
.icon-back:before {
  content: '\0046';
}
.icon-new-msg:before {
  content: '\0047';
}
.icon-new-applications:before {
  content: '\0048';
}
.icon-dentist:before {
  content: '\0049';
}
.icon-edit:before {
  content: '\004a';
}
.icon-egn:before {
  content: '\004b';
}
.icon-exit:before {
  content: '\004c';
}
.icon-location:before {
  content: '\004d';
}
.icon-mail:before {
  content: '\004e';
}
.icon-msg-ans:before {
  content: '\004f';
}
.icon-msg-new:before {
  content: '\0050';
}
.icon-msg-open:before {
  content: '\0051';
}
.icon-name:before {
  content: '\0052';
}
.icon-nav:before {
  content: '\0053';
}
.icon-new:before {
  content: '\0054';
}
.icon-pass:before {
  content: '\0055';
}
.icon-search:before {
  content: '\0056';
}
.icon-new:before {
  content: '\0057';
}
.icon-phone:before {
  content: '\0058';
}
.icon-rele:before {
  content: '\0059';
}
.icon-server:before {
  content: '\005a';
}
.icon-services:before {
  content: '\0061';
}
.icon-testing:before {
  content: '\0062';
}
.icon-user-new:before {
  content: '\0063';
}
.icon-forward:before {
  content: '\0064';
}
.icon-delete:before {
  content: '\0065';
}
.icon-download:before {
  content: '\0066';
}
.icon-folder1:before {
  content: '\0067';
}
.icon-folder2:before {
  content: '\0068';
}
.icon-print:before {
  content: '\0069';
}
.icon-save:before {
  content: '\006a';
}
.icon-avatar:before {
  content: '\006b';
}
.icon-pfst:before {
  content: '\006c';
}
.icon-pl:before {
  content: '\006d';
}
.icon-plst:before {
  content: '\006e';
}
.icon-pr:before {
  content: '\006f';
}
.icon-down:before {
  content: '\0070';
}
.icon-up:before {
  content: '\0071';
}
.icon-folder:before {
  content: '\0072';
}
.icon-invert:before {
  content: '\0073';
}
.icon-flipH:before {
  content: '\0074';
}
.icon-flipV:before {
  content: '\0075';
}
.icon-rotate:before {
  content: '\0076';
}
.icon-homeArrow:before {
  content: '\0077';
}
.icon-s1:before {
  content: '\0078';
}
.icon-s2:before {
  content: '\0079';
}
.icon-s3:before {
  content: '\007a';
}
.icon-s4:before {
  content: '\0030';
}
.icon-s5:before {
  content: '\0031';
}
.icon-fb:before {
  content: '\0032';
}
.icon-logo:before {
  content: '\0033';
}
.icon-time:before {
  content: '\0034';
}
.icon-111:before {
  content: '\0035';
}
.icon-sAnfas:before {
  content: '\0036';
}
.icon-sProfile:before {
  content: '\0037';
}
.icon-software:before {
  content: '\0038';
}
.icon {
  font-family: 'icons';
}
.roboto {
  font-family: 'Roboto', sans-serif;
}
.roboto-condensed {
  font-family: 'Roboto Condensed', sans-serif;
}

html {
  background: $background;
  box-sizing: border-box;
  color: $color;
  font-size: 13px;
  font-family: 'Google Sans', sans-serif;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  margin: 0;
  min-width: 320px;
  padding: 0;

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1.7rem;
  letter-spacing: 0.05rem;
}

.center {
  text-align: center;
}
.right {
  text-align: right;
}

.nowrap {
  white-space: nowrap;
}

.strong {
  font-weight: 700;
}

.error {
  color: $error;
}

.accent {
  color: $accent;
}

.sub {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.8rem;
  line-height: 0.8rem;
}

.table-wrapper {
  min-height: 165px;
  overflow: auto;
  width: 100%;
  .table {
    border-spacing: 0 10px;
    font-size: 0.9rem;
    width: 100%;
    tr {
      td,
      th {
        padding: 10px;
        text-align: left;
        &.center {
          text-align: center;
        }
      }
    }
    tbody {
      tr {
        th {
          font-weight: 700;
        }
      }
    }
    tbody {
      tr {
        cursor: pointer;
        td {
          background-color: $white--greyish;
          // @include transition(all $transition $cubic);
        }
        &:hover {
          td {
            background-color: #ededed;
          }
        }
        &.row--selected {
          td {
            background-color: #416184;
            color: #fff;
            .sub {
              color: rgba($color: #fff, $alpha: 0.6)
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $m) {
  html {
    font-size: 12px;
  }
}

@media screen and (max-width: $sm) {
  .desktop {
    display: none !important;
  }
}
</style>