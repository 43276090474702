<template>
  <Panel :absolute="true">
    <div class="panel__content">
      <h2>
        Ценоразпис на услугите
        <span class="icon close" @click="$emit('close-panel')">L</span>
      </h2>
      <p>Извършвани от Медико диагностична лаборатория 3В-РЕНТГЕН</p>
      <div class="table">
        <table>
          <tr>
            <td>1. Зъбни снимки (дигитални или на плака/филм)</td>
            <td>10 лв.</td>
          </tr>
          <tr>
            <td>2. Оклузални рентгенографии (коса по Belot, аксиална по Simpson)</td>
            <td>30 лв.</td>
          </tr>
          <tr>
            <td>3. Кариесна диагностика /bite wing/ (дигитални или на плака/филм)</td>
            <td>20 лв.</td>
          </tr>
          <tr>
            <td>4. Дигитална ортопантомография /панорамна снимка/</td>
            <td>40 лв. - <i>on-line</i><br>45 лв. - <i>плака + on-line</i></td>
          </tr>
          <tr>
            <td>5. Дигитална рентгенография на темпоромандибуларни стави</td>
            <td>40 лв. - <i>on-line</i><br>45 лв. - <i>плака + on-line</i></td>
          </tr>
          <tr>
            <td>6. Телерентгенография на череп (лицева, профилна) </td>
            <td>40 лв. - <i>on-line</i><br>45 лв. - <i>плака + on-line</i></td>
          </tr>
          <tr>
            <td>7. Рентгенография на киткова става (за ортодонтски цели)</td>
            <td>40 лв. - <i>on-line</i><br>45 лв. - <i>плака + on-line</i></td>
          </tr>
          <tr>
            <td>8. Рентгенография на околоносните кухини</td>
            <td>40 лв. - <i>on-line</i><br>45 лв. - <i>плака + on-line</i></td>
          </tr>
          <tr>
            <td>9. Комплютърна томография (3D скенер) на квадрант от челюстите</td>
            <td>120 лв.</td>
          </tr>
          <tr>
            <td>10. Комплютърна томография (3D скенер) на една челюст</td>
            <td>150 лв.</td>
          </tr>
          <tr>
            <td>11. Комплютърна томография (3D скенер) на двете челюсти</td>
            <td>190 лв.</td>
          </tr>
          <tr>
            <td>12. Компютърната томография на цял череп / TMC</td>
            <td>220 лв.</td>
          </tr>
          <tr>
            <td>13. Разчитане на 2D изследвания</td>
            <td>30 лв.</td>
          </tr>
          <tr>
            <td>14. Разчитане на 3D изследвания</td>
            <td>50 лв. - <i>на една челюст</i><br>70 лв. - <i>на две челюсти</i></td>
          </tr>
        </table>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel.vue';

export default {
  components: {
    Panel
  }
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';
@import '@/scss/mixins.scss';
.panel__content {
  overflow: initial;
  padding: 40px 50px;
  position: relative;
  &::before {
    background-image: url('../../assets/img/home/bg-pricelist.jpg');
    background-size: cover;
    content: '';
    height: 100%;
    left: 0;
    opacity: 0.1;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .close {
    font-size: 1.2rem;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 1;
    @include transform(translateY(-50%));
    @include noselect();
    @include hover();
  }
  > h2,
  p,
  .table {
    position: relative;
    z-index: 1;
  }
  > h2,
  p {
    font-weight: 700;
    text-align: center;
  }
  > h2 {
    margin-bottom: 5px;
  }
  .table {
    height: 100%;
    margin-top: 20px;
    overflow: auto;
    @include scrollbar(5px, #dadada);
    table {
      border-collapse: collapse;
      height: 100%;
      width: 100%;
      td {
        font-weight: 700;
        padding: 15px 40px;
      }
      tr:nth-child(odd) {
        td {
          background: rgba($color: #0077c7, $alpha: 0.2);
          &:first-child {
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
          }
          &:last-child {
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
@media screen and (max-width: $sm) {
  .panel__content {
    padding: 20px;
    .table table td {
      padding: 10px 20px;
    }
  }
}
</style>