const state = {
  error: null,
}

const getters = {
  currentError: state => state.error,
}

const actions = {
  error(context, error) {
    context.commit('setError', error);
  }
}

const mutations = {
  setError(state, err) {
    state.error = err;
  },
  purgeError(state) {
    state.error = null;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};