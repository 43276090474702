import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Main.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/to-jpg/:examination/:photo',
    name: 'to-jpg',
    component: () => import('../views/app/ToJPG.vue')
  },
  {
    name: 'app',
    path: '/app',
    component: () => import('../views/app/Main.vue'),
    children: [{
      path: 'examinations/:id?',
      name: 'app-examinations',
      component: () => import('../views/app/Examinations.vue'),
      meta: {
        requiresAuth: true
      },
    }, {
      path: 'roentgenography',
      name: 'app-roentgenography',
      component: () => import('../views/app/Roentgenography.vue'),
      meta: {
        requiresAuth: true
      },
    }, {
      path: 'users',
      name: 'app-users',
      component: () => import('../views/app/Users.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['admin', 'employee', 'dentist']
      },
    }, {
      path: 'inquiries',
      name: 'app-inquiries',
      component: () => import('../views/app/Inquiries.vue'),
      meta: {
        requiresAuth: true,
        rolesAllowed: ['admin', 'employee']
      },
    }, {
      path: 'noreg-examination',
      name: 'app-noreg-examination',
      component: () => import('../views/app/NoregExamination.vue'),
    }]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      // if only specific roles are allowed
      if (to.meta.rolesAllowed) {
        if (to.meta.rolesAllowed.indexOf(store.getters.currentUser.role) > -1) {
          return next();
        } else {
          return next({
            path: from.path || '/',
          });
        }
      }
      return next();
    }
    next({
      path: '/',
    });
  } else {
    next();
  }
})



export default router